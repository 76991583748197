import "./CustomerVehicleDetails.css"
import React, { useCallback, useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCustomerVehicle } from "../../../redux-slice/CustomerVehicleSlice";
import { getProfile } from "../../../redux-slice/ProfileSlice";
import { Container, Grid, GridColumn, GridRow, Header, Image, Segment } from "semantic-ui-react";
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import Navigation from "../../common/Navigation";
import ROLE from "../../../constants/Roles";
import RoleAllowed from "../../common/RoleAllowed";
import UpdateCustomerFirstNameModal from "../../customer/details/UpdateCustomerFirstName";
import UpdateCustomerLastNameModal from "../../customer/details/UpdateCustomerLastName";
import UpdateCustomerMobileNumberModal from "../../customer/details/UpdateCustomerMobileNumber";
import UpdateCustomerAddressModal from "../../customer/details/UpdateCustomerAddress";
import UpdateCustomerVehicleRegistrationNumber from "../../customerVehicle/details/UpdateCustomerVehicleRegistrationNumber";
import UpdateCustomerVehicleVinNumberModal from "../../customerVehicle/details/UpdateCustomerVehicleVinNumber";
import UpdateCustomerVehicleVehicleModal from "../../customerVehicle/details/UpdateCustomerVehicleVehicle";
import JobServiceHistory from "../../job/details/JobServiceHistory";

export default function CustomerVehicleDetails() {

    const authentication = useSelector(state => state.authentication);
    const customerVehicle = useSelector(state => state.customerVehicle.loading?null:state.customerVehicle.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const {id} = useParams();

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        }
        else
        {
            dispatch(getProfile());
            dispatch(getCustomerVehicle({customerVehicleId:id}));
        }
    },[authentication,id,dispatch,navigate]);

    useEffect(() => {
        onMount();
    },[onMount]);

    useEffect(() => {
        document.title = "Customer Vehicle Details";
    });

    const renderVehicleInformation = () => {
        if(customerVehicle) {
            return(
                <GridColumn width={8}>
                    <Header textAlign="left">{t(Text.UI_CUSTOMERVEHICLE_DETAILS_CUSTOMERVEHICLEINFORMATION)+" "}</Header>
                    <Segment textAlign="left">
                        <Grid columns={2} stackable>
                            <GridRow>
                                <GridColumn verticalAlign="middle" width={6}>
                                    <Image src={customerVehicle.vehicleData.picture} size="small"/>
                                </GridColumn>
                                <GridColumn width={10}>
                                    <Grid stackable>
                                        <GridRow>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_REGISTRATIONNUMBER)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleRegistrationNumber customerVehicle={customerVehicle} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{customerVehicle.registrationNumber}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_VEHICLE_TYPE)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleVehicleModal customerVehicle={customerVehicle} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{String()
                                                        .concat(customerVehicle.vehicleData?.brand)
                                                        .concat(customerVehicle.vehicleData?.model)
                                                        .concat(customerVehicle.vehicleData?.series)
                                                        .toUpperCase()
                                                    }</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_ENGINE)}</h4>
                                                    <p className="no-margin">{customerVehicle.vehicleData.engine}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_TRANSMISSION)}</h4>
                                                    <p className="no-margin">{customerVehicle.vehicleData?.transmission}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleVinNumberModal customerVehicle={customerVehicle} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{customerVehicle.vinNumber}</p>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            );
        }
    }

    const renderCustomerInformation = () => {
        if(customerVehicle) {
            const address1=customerVehicle.customerData?.addressLine1?customerVehicle.customerData.addressLine1:''; 
            const address2=customerVehicle.customerData?.addressLine2?customerVehicle.customerData.addressLine2:''; 
            const postcode=customerVehicle.customerData?.postcode?customerVehicle.customerData.postcode:''; 
            const city=customerVehicle.customerData?.city?customerVehicle.customerData.city:''; 
            const state=customerVehicle.customerData?.state?.value?customerVehicle.customerData.state.value:''; 
            return(
                <GridColumn width={8}>
                    <Header textAlign="left">{t(Text.UI_CUSTOMERVEHICLE_DETAILS_CUSTOMERINFORMATION)+" "}</Header>
                    <Segment textAlign="left">
                        <Grid columns={2} stackable>
                            <GridRow>
                                <GridColumn verticalAlign="middle" width={6} >
                                    <Image src={customerVehicle.customerData?.picture} size="small"/>
                                </GridColumn>
                                <GridColumn width={10}>
                                    <Grid stackable>
                                        <GridRow>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_FIRSTNAME)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerFirstNameModal customer={customerVehicle.customerData} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{customerVehicle.customerData?.firstName}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={8}>
                                            <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_LASTNAME)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerLastNameModal customer={customerVehicle.customerData} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{customerVehicle.customerData?.lastName}</p>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_MOBILENUMBER)}
                                                    <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                        <UpdateCustomerMobileNumberModal customer={customerVehicle.customerData} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                    </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{customerVehicle.customerData?.mobileNumber}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_FULLADDRESS)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerAddressModal customer={customerVehicle.customerData} onUpdate={() => {dispatch(getCustomerVehicle({customerVehicleId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <span className="no-margin">{address1 + (address1 === ''?'':', ')}</span>
                                                    <span className="no-margin">{address2 + (address2 === ''?'':', ')}</span>
                                                    <span className="no-margin">{postcode + (postcode === ''?'':', ')}</span>
                                                    <span className="no-margin">{city + (city === ''?'':', ')}</span>
                                                    <span className="no-margin">{state}</span>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            );               
        }
    }

    const renderJobServiceHistory = () => {
        if(customerVehicle) {
            return(
                <GridColumn width={16}>
                    <Header textAlign="left">{t(Text.UI_JOB_SERVICEHISTORY_HEADER)+" "}</Header>
                    <Segment textAlign="left">
                        <JobServiceHistory registrationNumber={customerVehicle.registrationNumber}/>
                    </Segment>
                </GridColumn>
            );               
        }
    }

    return (
        <Navigation headerText={t(Text.UI_CUSTOMERVEHICLE_HEADER)} rolesAllowed={[ROLE.ROLE_ADVISOR,ROLE.ROLE_MANAGER]}>
            <Container fluid>
                <Segment className='primary-segment' basic>
                    <Grid stackable >
                        <GridRow columns={2}>
                            {renderVehicleInformation()}
                            {renderCustomerInformation()}
                        </GridRow>
                        <GridRow columns={2}>
                            {renderJobServiceHistory()}
                        </GridRow>
                    </Grid>
                </Segment>
            </Container>
        </Navigation>
    );

}