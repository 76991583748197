import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LOGIN_PAGE,FORGOT_PASSWORD_PAGE, DASHBOARD_PAGE, PROFILE_PAGE, JOB_DETAILS_PAGE, JOB_PAGE, CUSTOMERVEHICLE_PAGE, CUSTOMERVEHICLE_DETAILS_PAGE } from '../../constants/Path'
import { Login, ForgotPassword, Dashboard, Profile, JobDetails } from '..';
import { Suspense } from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Job from '../job/Job';
import CustomerVehicle from '../customerVehicle/CustomerVehicle';
import CustomerVehicleDetails from '../customerVehicle/details/CustomerVehicleDetails';

//i18n Configuration
i18n.use(Backend).use(I18nextBrowserLanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en-MY',
  interpolation: {
    escapeValue: false
  }
});

export default function App() {
  return (
    <Suspense>
      <BrowserRouter>
        <div className="App">
            <Routes>
              <Route exact path={LOGIN_PAGE} element={<Login/>} />
              <Route exact path={FORGOT_PASSWORD_PAGE} element={<ForgotPassword/>} />
              <Route exact path={DASHBOARD_PAGE} element={<Dashboard/>} />
              <Route exact path={PROFILE_PAGE} element={<Profile/>} />
              <Route exact path={JOB_PAGE} element={<Job/>} />
              <Route exact path={JOB_DETAILS_PAGE} element={<JobDetails/>} />
              <Route exact path={CUSTOMERVEHICLE_PAGE} element={<CustomerVehicle/>} />
              <Route exact path={CUSTOMERVEHICLE_DETAILS_PAGE} element={<CustomerVehicleDetails/>} />
            </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}
