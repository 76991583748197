import React, { useState } from 'react'
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, FormDropdown, Grid, GridColumn, GridRow, Message} from "semantic-ui-react";
import { isMobile } from 'react-device-detect';
import NewCustomerVehicleModal from '../../customerVehicle/new/NewCustomerVehicleModal';
import VisibleCondition from '../VisibleCondition';

export default function CustomerVehicleDropdown (props) {
    const authentication = useSelector(state => state.authentication);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [customerVehicleOptions, setCustomerVehicleOptions] = useState([])

    const handleOnSearchChange = async (event,value) => {
        setLoading(true);
        event.preventDefault();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT+"/search?searchText="+value.searchQuery,options)
        const body = await response.json();
        
        if(response.ok)
        {
            setCustomerVehicleOptions(body?body.map(item => {
                let container = {};
                container['value'] = item.id;
                container['text'] = item.registrationNumber;
                container['customervehicle'] = item;
                return container;
            }):[]);
            setLoading(false);
            setError(false)
        }
        else{
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    return (
        <Form>
            <Grid>
                <GridRow columns={2}>
                    <GridColumn width={isMobile?13:14}>
                        <FormDropdown required label={t(Text.UI_JOB_DETAILS_CARPLATENUMBER)} loading={loading} placeholder={t(Text.UI_DROPDOWN_CUSTOMERVEHICLE_PLACEHOLDER)} fluid selection search options={customerVehicleOptions} onSearchChange={handleOnSearchChange} onChange={props.handleSelectCustomerVehicle}/>
                    </GridColumn>
                    <GridColumn verticalAlign='bottom' textAlign='right' width={2}>
                        <NewCustomerVehicleModal handleCreateCustomerVehicle={props.handleCreateCustomerVehicle}/>
                    </GridColumn>
                </GridRow>
                <VisibleCondition condition={error}>
                    <GridRow columns={1}>
                        <GridColumn width={16}>
                            <Message visible={error} className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </GridColumn>
                    </GridRow>
                </VisibleCondition>
            </Grid>
            <br/>
        </Form>
    );
}
