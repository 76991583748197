import React from 'react'
import { useSelector } from 'react-redux';
import { FormDropdown } from "semantic-ui-react";

export default function EmployeeDropdown (props) {
    const employees = useSelector(state => state.employees);
    const employeeListOptions = employees.data?employees.data.filter(item => item.enabled).map(item => {
        let container = {};
        container['value'] = item.id;
        container['text'] = item.firstName+' '+item.lastName;
        return container;
    }):[];

    return (
        <FormDropdown required={props.required} label={props.label} placeholder={props.placeholder} fluid multiple={props.multiple} selection search options={employeeListOptions} onChange={props.handleSelectEmployee} defaultValue={props.defaultValue} /> 
    );
}
