// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-header{
    background-color:#DD383B!important;
}

.menu-item-header{
    color:#FFFFFF!important;
}

.field-input-hidden{
    margin: 0!important;
}

.accordion{
    background-color: whitesmoke!important;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".menu-header{\n    background-color:#DD383B!important;\n}\n\n.menu-item-header{\n    color:#FFFFFF!important;\n}\n\n.field-input-hidden{\n    margin: 0!important;\n}\n\n.accordion{\n    background-color: whitesmoke!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
