// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-information-container{
    padding-left:5vw!important;
    padding-right:5vw!important;
}

.change-password-container{
    padding-left:15vw!important;
    padding-right:15vw!important;
}

.header-button{
    margin-left:1vw!important;
    margin-bottom:1vh!important;
}

.primary-segment{
    min-height: 90vh!important;
    margin-top: 2vh!important;
    margin-bottom: 2vh!important;
}

.primary-header{
    color: black;
    font-size: 1.5em;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/Profile.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".profile-information-container{\n    padding-left:5vw!important;\n    padding-right:5vw!important;\n}\n\n.change-password-container{\n    padding-left:15vw!important;\n    padding-right:15vw!important;\n}\n\n.header-button{\n    margin-left:1vw!important;\n    margin-bottom:1vh!important;\n}\n\n.primary-segment{\n    min-height: 90vh!important;\n    margin-top: 2vh!important;\n    margin-bottom: 2vh!important;\n}\n\n.primary-header{\n    color: black;\n    font-size: 1.5em;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
