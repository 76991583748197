export const removeEmployeeRole = (value) => {
    if(value)
    {
        const roles = [...value];
        const index = roles.indexOf("EMPLOYEE");
        if (index > -1) { 
            roles.splice(index, 1); 
        }

        return roles;
    }
}