import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { JOB_ENDPOINT } from "../constants/API";

export const getCheckedInJobs = createAsyncThunk(
    'checkedInJobs/get',
    async (_data, thunkAPI) => {
      const response = await fetch(JOB_ENDPOINT+'/activeJobs?jobStatus=CHECKED_IN',{
        method: 'GET',
            headers: new Headers({
            'Authorization': thunkAPI.getState().authentication.authorization
        }),
        redirect: 'follow'
      })
  
      if(!response.ok){
        return thunkAPI.rejectWithValue((await response.json()));
      }
      else{
        return await response.json();
      }
    }
)

const CheckedInJobsSlice = createSlice({
    name:'checkedInJobs',
    initialState:{
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        //START GET CHECKED IN JOB REDUCERS
        builder.addCase(getCheckedInJobs.pending, (state, _action) => {
            state.success = false;
            state.error = false;
            state.errorMessage = null;
            state.loading = true;
        })
        builder.addCase(getCheckedInJobs.fulfilled, (state, action) => {
            state.data = action.payload;
            state.success = true;
            state.error = false;
            state.errorMessage = null;
            state.loading = false;
          })
          builder.addCase(getCheckedInJobs.rejected, (state, action) => {
            state.data = null;
            state.success = false;
            state.error = true;
            state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Checked In Jobs";
            state.loading = false;
          })
        //END GET CHECKED IN JOB REDUCERS
    }
});

export const { resetSuccess } = CheckedInJobsSlice.actions;

export default CheckedInJobsSlice.reducer;