import React, {useEffect, useState} from 'react'
import Text from '../../constants/Text';
import ENDPOINT from '../../constants/API';
import { useTranslation } from 'react-i18next'
import { Button, Form, Grid, Image, Segment, Message, FormInput, GridColumn } from 'semantic-ui-react'

export default function ForgotPassword() {

  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.title = "Forgot Password";
  });
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)

    const email = Object.fromEntries(new FormData(event.target).entries());

    const options = {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(email)
    }
    
    const response = await fetch(ENDPOINT.FORGOT_PASSWORD_ENDPOINT,options)
    const body = await response.json()
    setMessage(body.message);

    if(response.ok)
    {
      setSuccess(true)
      setError(false)
    }
    else
    {
      setError(true)
      setSuccess(false)
    }

    setLoading(false);
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <GridColumn style={{ maxWidth: 500, margin:30}}>
          <Image src='https://res.cloudinary.com/vebo-connect/image/upload/vebo_logo_FA_kdzmrw.png' size='medium' centered/> 
          <Form error={error} success={success} className='ui form' size='large' onSubmit={handleSubmit}>
            <Segment loading={loading} >
                <FormInput name='email' fluid icon='mail' iconPosition='left' placeholder={t(Text.UI_FORGOTPASSWORD_PLACEHOLDER_EMAIL)}/>
                <Button className="vebo-submit-button" fluid size='large'>{t(Text.UI_FORGOTPASSWORD_BUTTON_RESETPASSWORD)}</Button>
                <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={message}/>
                <Message className='support-line-break' success header={t(Text.GENERIC_MESSAGE_HEADER_SUCCESS)} content={message}/>
            </Segment>
          </Form>
      </GridColumn>
    </Grid>
  )
}