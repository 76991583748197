import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import VehicleDropdown from '../../common/dropdown/VehicleDropdown';

export default function UpdateCustomerVehicleVehicleModal (props) {
    
    const authentication = useSelector(state => state.authentication)
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedVehicle, setSelectedVehicle] = useState(props?.customerVehicle?.vehicleData?.id);

    const handleUpdateCustomerVehicleVehicleModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCustomerVehicleVehicle = {
            vehicle:selectedVehicle
        }
    
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCustomerVehicleVehicle)
        }
        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT+"/"+props.customerVehicle?.id+"/updateVehicle",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateCustomerVehicleVehicleModal();
            props.onUpdate();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateCustomerVehicleVehicleButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateCustomerVehicleVehicleModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateCustomerVehicleVehicleModal = () => {
        setOpen(true);
    }

    const handleSelectVehicle = (event,data) => {
        event.preventDefault();
        setSelectedVehicle(data.value);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateCustomerVehicleVehicleModal} onOpen={openUpdateCustomerVehicleVehicleModal} trigger={updateCustomerVehicleVehicleButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateCustomerVehicleVehicleModal} error={error}>
                            <VehicleDropdown handleSelectVehicle={handleSelectVehicle}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    )
}