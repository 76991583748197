import "./NewIncidentModal.css";
import React, { useState } from 'react'
import Text from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';
import {useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormCheckbox, FormDropdown, FormInput, FormTextArea, Message, Modal, ModalContent, ModalHeader } from "semantic-ui-react";
import { getJob } from "../../../redux-slice/JobSlice";
import EmployeeDropdown from '../../common/dropdown/EmployeeDropdown';

export default function NewIncidentModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const categoryOptions = [
        {
            key: 'VEBO',
            text: t(Text.UI_INCIDENT_DETAILS_VEBO),
            value: 'VEBO'
        },
        {
            key: 'SUPPLIER',
            text: t(Text.UI_INCIDENT_DETAILS_SUPPLIER),
            value: 'SUPPLIER'
        },
        {
            key: 'CUSTOMER',
            text: t(Text.UI_INCIDENT_DETAILS_CUSTOMER),
            value: 'CUSTOMER'
        }
    ]

    const [selectedPersonResponsible, setSelectedPersonResponsible] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0]);
    const [resolved, setResolved] = useState(false);

    const handleCreateNewIncidentAction = async (event) => {
        setLoading(true);
        event.preventDefault();
        const createNewIncident = Object.fromEntries(new FormData(event.target).entries());

        createNewIncident.job = props.job.id;
        createNewIncident.personResponsible = selectedPersonResponsible;
        createNewIncident.category = selectedCategory;
        createNewIncident.resolved = resolved;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(createNewIncident)
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeNewIncidentModal();
            dispatch(getJob({jobId:body.job?.id}));
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const closeNewIncidentModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openNewIncidentModal = () => {
        setOpen(true);
    }

    const newIncidentModalButton = () => {
        return(
            <Button floated='right' icon='exclamation triangle' color="red" content={t(Text.UI_INCIDENT_MODAL_CREATEINCIDENT)}/>
        )
    }

    const handleSelectedPersonResponsible = (event,data) => {
        event.preventDefault();
        setSelectedPersonResponsible(data.value);
    }

    const handleSelectCategory = (event,data) => {
        event.preventDefault();
        setSelectedCategory({key:data.value});
    }

    return(
        <Modal centered closeIcon={true} open={open} onClose={closeNewIncidentModal} onOpen={openNewIncidentModal} trigger={newIncidentModalButton()}>
            <ModalHeader>{t(Text.UI_INCIDENT_MODAL_NEWINCIDENT)}</ModalHeader>
            <ModalContent>
                <Form onSubmit={handleCreateNewIncidentAction} loading={loading}>
                    <EmployeeDropdown label={t(Text.GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE)} placeholder={t(Text.GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE)} required={true} multiple={true} handleSelectEmployee={handleSelectedPersonResponsible}/>
                    <FormInput label={t(Text.GENERIC_DATA_INCIDENT_COST)} placeholder={t(Text.GENERIC_DATA_INCIDENT_COST)} type='number' step="any" name="cost" required={true} />
                    <FormTextArea label={t(Text.GENERIC_DATA_INCIDENT_REPORT)} placeholder={t(Text.GENERIC_DATA_INCIDENT_REPORT)} name="report" required={true}/>
                    <FormDropdown label={t(Text.GENERIC_DATA_INCIDENT_CATEGORY)} name="category" placeholder="Choose Incident Category" fluid selection required options={categoryOptions} onChange={handleSelectCategory} defaultValue={"VEBO"}/>
                    <FormCheckbox label={t(Text.GENERIC_DATA_INCIDENT_RESOLVED)} checked={resolved} onChange={(e,data) => setResolved(data.checked)} toggle/>
                    <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                    <Message className='support-line-break' error visible={error} header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage}/>
                </Form>
            </ModalContent>
        </Modal>
    )
}