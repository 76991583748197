import "./JobDetails.css"
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';   
import { Grid, GridColumn, GridRow, Segment, Icon, Accordion, AccordionTitle, AccordionContent, Transition, Message } from "semantic-ui-react";
import { convertDateTimeToTime } from "../../utils/DateTimeFormatUtil";
import { DATE_TIME_FORMAT } from "../../../constants/Global";

export default function JobServiceHistory (props){
    const navigate = useNavigate();
    const {t} = useTranslation();
    const authentication = useSelector(state => state.authentication)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [activeSegments, setActiveSegments] = useState([]);
    const [jobHistory, setJobHistory] = useState([]);

    const getJobHistory = useCallback(async () => {
        setLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/retrieveJobHistory?registrationPlateNumber="+props.registrationNumber,options)
        const body = await response.json();

        if(response.ok)
        {
            var result = body;

            if(props.filtered){
                result = body.filter(n => n.jobStatus.key === "CHECKED_OUT")
            }

            setJobHistory(result)
        }
        else{
            setError(true);
            setErrorMessage(body.message);
        }
        setLoading(false)
    },[authentication.authorization,props.registrationNumber,props.filtered])

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        } 
        else 
        {
            //START ON MOUNT DATA LOAD
            getJobHistory()
        }
    },[authentication,navigate,getJobHistory]);
    
    useEffect(() => {
        onMount();
    },[onMount]);
   
    const toggleSegment = (index) => {
        const newActiveSegments = [...activeSegments];
        const currentIndexPosition = activeSegments.indexOf(index);
        
        if (newActiveSegments.includes(index)) 
        {
            newActiveSegments.splice(currentIndexPosition, 1)
            setActiveSegments(newActiveSegments);
        } 
        else 
        {
            newActiveSegments.push(index)
            setActiveSegments(newActiveSegments);
        }
    }

    const activeBookingsTitle = (job) => {
        return(
            <Grid>
                <GridColumn width={5} textAlign="left">
                    <Icon name='dropdown'/>
                    {convertDateTimeToTime(job.jobStartDateTime, DATE_TIME_FORMAT)}
                </GridColumn>
                <GridColumn width={6} textAlign="center">
                    {job.serviceData.customerVehicleData.registrationNumber}
                </GridColumn>
                <GridColumn width={5} textAlign="right">
                    <a href={generatePath(PATH.JOB_DETAILS_PAGE,{id:job.id})}>{job.jobId} <Icon name="external"/></a>
                </GridColumn>
            </Grid>
        )
    }

    const renderContent = (value,idx) => {
        return(
            <GridColumn key={idx}>
                <AccordionTitle key={idx} active={activeSegments.includes(idx)} icon={false} onClick={() => toggleSegment(idx)} content={activeBookingsTitle(value)} className='align-left no-pointer'/>
                <Transition visible={activeSegments.includes(idx)} animation='fade down' duration={300}>
                    <AccordionContent>
                        <Segment basic>
                            <Grid stackable>
                                <GridRow>
                                    <GridColumn width={5} textAlign="left">
                                        <div>
                                            <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}</h4>
                                            <p className="support-line-break">{value.customerRequest}</p>
                                        </div>
                                    </GridColumn>
                                    <GridColumn width={6} textAlign="left">
                                        <div>
                                            <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}</h4>
                                            <p className="support-line-break">{value.description}</p>
                                        </div>
                                    </GridColumn>
                                    <GridColumn width={5} textAlign="left">
                                        <div>
                                            <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_ABNORMALITIES)}</h4>
                                            <p className="support-line-break">{value.abnormalities}</p>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </Segment>
                    </AccordionContent>
                </Transition>
            </GridColumn>
        )
    }

    return(
        <Segment loading={loading} basic>
            <Grid stackable padded centered>
                <GridRow>
                    <Accordion className="accordion" styled fluid>
                        {jobHistory.length>0?jobHistory.map((value,idx) => {return renderContent(value,idx)}):error?<Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage}/>:"No Service History for this Vehicle"}
                    </Accordion>
                </GridRow>
            </Grid>
        </Segment>
    )
}
