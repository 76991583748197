import React, { useCallback, useEffect } from 'react'
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getVehiclesList } from "../../../redux-slice/VehiclesSlice";
import { FormDropdown } from "semantic-ui-react";

export default function VehicleDropdown (props) {
    const authentication = useSelector(state => state.authentication);
    const vehicles = useSelector(state => state.vehicles);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const handleSelectVehicle = props.handleSelectVehicle;

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        } 
        else 
        {
            //START ON MOUNT DATA LOAD
            dispatch(getVehiclesList());
        }
    },[authentication,dispatch,navigate]);

    useEffect(() => {
        onMount();
    },[onMount]);

    const vehicleListOptions = vehicles.data?vehicles.data.map(item => {
        let container = {};
        container['value'] = item.id;
        container['text'] = item.posId;
        container['vehicle'] = item;
        return container;
    }):[];

    return (
        <FormDropdown required={props.required} label={t(Text.GENERIC_DATA_VEHICLE_TYPE)} name="vehicle" placeholder="Select Vehicle" fluid selection search options={vehicleListOptions} onChange={handleSelectVehicle} />
    );
}
