module.exports = {
    WARNING_HEADER                                                              : "warning.header",
    WARNING_LOGOUT_CONFIRMATION                                                 : "warning.logout.confirmation",
    WARNING_JOB_CANCEL                                                          : "warning.job.cancel",
    WARNING_JOB_RESTART                                                         : "warning.job.restart",
    //GENERIC
    GENERIC_BACK                                                                : "generic.back",
    GENERIC_SUBMIT                                                              : "generic.submit",
    GENERIC_MESSAGE_HEADER_SUCCESS                                              : "generic.message.header.success",
    GENERIC_MESSAGE_HEADER_ERROR                                                : "generic.message.header.error",
    GENERIC_DATA_PROFILE_USERNAME                                               : "generic.data.profile.username",
    GENERIC_DATA_PROFILE_FIRSTNAME                                              : "generic.data.profile.firstname",
    GENERIC_DATA_PROFILE_LASTNAME                                               : "generic.data.profile.lastname",
    GENERIC_DATA_PROFILE_MOBILENUMBER                                           : "generic.data.profile.mobilenumber",
    GENERIC_DATA_PROFILE_GENDER                                                 : "generic.data.profile.gender",
    GENERIC_DATA_PROFILE_EMAIL                                                  : "generic.data.profile.email",
    GENERIC_DATA_PROFILE_BIRTHDAY                                               : "generic.data.profile.birthday",
    GENERIC_DATA_CHANGEPASSWORD_CURRENTPASSWORD                                 : "generic.data.password.currentpassword",
    GENERIC_DATA_CHANGEPASSWORD_NEWPASSWORD                                     : "generic.data.password.newpassword",
    GENERIC_DATA_CHANGEPASSWORD_CONFIRMNEWPASSWORD                              : "generic.data.password.confirmnewpassword",
    GENERIC_DATA_JOB_JOBSTATUS                                                  : "generic.data.job.jobstatus",
    GENERIC_DATA_JOB_REPAIRDESCRIPTION                                          : "generic.data.job.repairdescription",                
    GENERIC_DATA_JOB_STARTDATETIME                                              : "generic.data.job.startdatetime",
    GENERIC_DATA_JOB_ENDDATETIME                                                : "generic.data.job.enddatetime",
    GENERIC_DATA_JOB_SERVICEADVISOR                                             : "generic.data.job.serviceadvisor",
    GENERIC_DATA_JOB_SERVICELEADTECHNICIAN                                      : "generic.data.job.serviceleadtechnician",
    GENERIC_DATA_JOB_SERVICETECHNICIANS                                         : "generic.data.job.servicetechnicians",
    GENERIC_DATA_JOB_JOBID                                                      : "generic.data.job.jobid",
    GENERIC_DATA_JOB_CREATE_SELECT_CUSTOMER                                     : "generic.data.job.create.selectcustomer",
    GENERIC_DATA_JOB_CREATE_SELECT_VEHICLE                                      : "generic.data.job.create.selectvehicle",
    GENERIC_DATA_BOOKING_STATUS                                                 : "generic.data.booking.status",
    GENERIC_DATA_BOOKING_DESCRIPTION                                            : "generic.data.booking.description",
    GENERIC_DATA_BOOKING_BOOKINGTIME                                            : "generic.data.booking.bookingtime",
    GENERIC_DATA_BOOKING_BOOKINGDATETIME                                        : "generic.data.booking.bookingdatetime",
    GENERIC_DATA_BOOKING_CUSTOMERMILEAGE                                        : "generic.data.booking.customermileage",
    GENERIC_DATA_CUSTOMER_CUSTOMER                                              : "generic.data.customer.customer",
    GENERIC_DATA_CUSTOMER_CUSTOMERNAME                                          : "generic.data.customer.customername",
    GENERIC_DATA_CUSTOMER_PHONENUMBER                                           : "generic.data.customer.phonenumber",
    GENERIC_DATA_CUSTOMER_FIRSTNAME                                             : "generic.data.customer.firstname",
    GENERIC_DATA_CUSTOMER_LASTNAME                                              : "generic.data.customer.lastname",
    GENERIC_DATA_CUSTOMER_MOBILENUMBER                                          : "generic.data.customer.mobilenumber",
    GENERIC_DATA_CUSTOMER_GENDER                                                : "generic.data.customer.gender",
    GENERIC_DATA_CUSTOMER_STATE                                                 : "generic.data.customer.state",
    GENERIC_DATA_CUSTOMER_CITY                                                  : "generic.data.customer.city",
    GENERIC_DATA_CUSTOMER_POSTCODE                                              : "generic.data.customer.postcode",
    GENERIC_DATA_CUSTOMER_ADDRESSLINE1                                          : "generic.data.customer.addressline1",
    GENERIC_DATA_CUSTOMER_ADDRESSLINE2                                          : "generic.data.customer.addressline2",
    GENERIC_DATA_CUSTOMER_FULLADDRESS                                           : "generic.data.customer.fulladdress",    
    GENERIC_DATA_CUSTOMER_BIRTHDATE                                             : "generic.data.customer.birthdate",
    GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER                                      : "generic.data.customervehicle.vinnumber",
    GENERIC_DATA_CUSTOMERVEHICLE_REGISTRATIONNUMBER                             : "generic.data.customervehicle.registrationnumber",
    GENERIC_DATA_CUSTOMERVEHICLE_TRANSMISSION                                   : "generic.data.customervehicle.transmission",
    GENERIC_DATA_CUSTOMERVEHICLE_ENGINE                                         : "generic.data.customervehicle.engine",
    GENERIC_DATA_CUSTOMERVEHICLE_CUSTOMERMILEAGE                                : "generic.data.customervehicle.customermileage",
    GENERIC_DATA_CUSTOMERVEHICLE_LASTCUSTOMERMILEAGEMODIFIEDDATE                : "generic.data.customervehicle.lastcustomermileagemodifieddate",
    GENERIC_DATA_VEHICLE_TYPE                                                   : "generic.data.vehicle.type",
    GENERIC_DATA_VEHICLE_POSID                                                  : "generic.data.vehicle.posid",
    GENERIC_DATA_SERVICE_MILEAGE                                                : "generic.data.service.mileage",
    GENERIC_DATA_TAG_MAINTENANCE                                                : "generic.data.tag.maintenance",
    GENERIC_DATA_TAG_REPAIR                                                     : "generic.data.tag.repair",
    GENERIC_DATA_TAG_MAINTENANCE_PLACEHOLDER                                    : "generic.data.tag.maintenanceplaceholder",
    GENERIC_DATA_TAG_REPAIR_PLACEHOLDER                                         : "generic.data.tag.repairplaceholder",
    GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE                                     : "generic.data.incident.personresponsible",
    GENERIC_DATA_INCIDENT_COST                                                  : "generic.data.incident.cost",         
    GENERIC_DATA_INCIDENT_REPORT                                                : "generic.data.incident.report",
    GENERIC_DATA_INCIDENT_CATEGORY                                              : "generic.data.incident.category", 
    GENERIC_DATA_INCIDENT_RESOLVED                                              : "generic.data.incident.resolved",
    GENERIC_DATA_LASTCHANGEDON                                                  : "generic.data.lastchangedon",
    GENERIC_DATA_LASTCHANGEDBY                                                  : "generic.data.lastchangedby",
    GENERIC_DATA_CHECKIN                                                        : "generic.data.checkin",
    GENERIC_DATA_STARTJOB                                                       : "generic.data.startjob",
    //UI
    UI_LOGIN_PLACEHOLDER_USERNAME                                               : "ui.login.placeholder.username",
    UI_LOGIN_PLACEHOLDER_PASSWORD                                               : "ui.login.placeholder.password",
    UI_LOGIN_MESSAGE_FORGOTPASSWORD_HEADER                                      : "ui.login.message.forgotpassword.header",
    UI_LOGIN_MESSAGE_FORGOTPASSWORD_CONTENT                                     : "ui.login.message.forgotpassword.content",
    UI_LOGIN_MESSAGE_WRONGCREDENTIALS_HEADER                                    : "ui.login.message.wrongcredentials.header",
    UI_LOGIN_MESSAGE_WRONGCREDENTIALS_CONTENT                                   : "ui.login.message.wrongcredentials.content",
    UI_LOGIN_LINK_FORGOTPASSWORD                                                : "ui.login.link.forgotpassword",
    UI_LOGIN_BUTTON_LOGIN                                                       : "ui.login.button.login",
    UI_FORGOTPASSWORD_PLACEHOLDER_EMAIL                                         : "ui.forgotpassword.placeholder.email",
    UI_FORGOTPASSWORD_BUTTON_RESETPASSWORD                                      : "ui.forgotpassword.button.resetpassword",
    UI_NAVIGATION_LOGOUT                                                        : "ui.navigation.logout",
    UI_NAVIGATION_MYPROFILE                                                     : "ui.navigation.myprofile",
    UI_NAVIGATION_DASHBOARD                                                     : "ui.navigation.dashboard",
    UI_NAVIGATION_CUSTOMERVEHICLE                                               : "ui.navigation.customervehicle",
    UI_NAVIGATION_JOB                                                           : "ui.navigation.job",
    UI_SETTINGS_HEADER                                                          : "ui.settings.header",
    UI_SETTINGS_PROFILE_HEADER                                                  : "ui.settings.profile.header",
    UI_SETTINGS_PROFILE_INFORMATION                                             : "ui.settings.profile.information",
    UI_SETTINGS_PROFILE_CHANGEPASSWORD                                          : "ui.settings.profile.changepassword",
    UI_SETTINGS_PROFILE_CURRENTPASSWORD                                         : "ui.settings.profile.currentpassword",
    UI_SETTINGS_PROFILE_NEWPASSWORD                                             : "ui.settings.profile.newpassword",
    UI_SETTINGS_PROFILE_CONFIRMNEWPASSWORD                                      : "ui.settings.profile.confirmnewpassword",
    UI_DASHBOARD_HEADER                                                         : "ui.dashboard.header",
    UI_DASHBOARD_BOOKING_HEADER                                                 : "ui.dashboard.booking.header",
    UI_DASHBOARD_ONGOINGJOB_HEADER                                              : "ui.dashboard.ongoingjob.header",
    UI_DASHBOARD_CHECKEDINJOB_HEADER                                            : "ui.dashboard.checkedinjob.header",
    UI_DASHBOARD_STARTED_HEADER                                                 : "ui.dashboard.startedjob.header",
    UI_DASHBOARD_COMPLETEDJOB_HEADER                                            : "ui.dashboard.completedjob.header",
    UI_DROPDOWN_CUSTOMERVEHICLE_PLACEHOLDER                                     : "ui.dropdown.customervehicle.placeholder",       
    UI_DROPDOWN_CUSTOMER_PLACEHOLDER                                            : "ui.dropdown.customer.placeholder",   
    UI_JOB_HEADER                                                               : "ui.job.header",
    UI_JOB_MESSAGE                                                              : "ui.job.message",
    UI_JOB_DETAILS_HEADER                                                       : "ui.job.details.header",
    UI_JOB_DETAILS_JOBINFORMATION                                               : "ui.job.details.jobinformation",
    UI_JOB_DETAILS_QUOTATIONS                                                   : "ui.job.details.quotations",
    UI_JOB_DETAILS_JOBPROGRESS                                                  : "ui.job.details.jobprogress",
    UI_JOB_DETAILS_JOBDETAILS                                                   : "ui.job.details.jobdetails",
    UI_JOB_DETAILS_BOOKINGINFORMATION                                           : "ui.job.details.bookinginformation",
    UI_JOB_DETAILS_CUSTOMERINFORMATION                                          : "ui.job.details.customerinformation",
    UI_JOB_DETAILS_CUSTOMERVEHICLEINFORMATION                                   : "ui.job.details.customervehicleinformation",
    UI_JOB_DETAILS_LASTSERVICEINFORMATION                                       : "ui.job.details.lastserviceinformation",
    UI_JOB_DETAILS_DISCUSSION                                                   : "ui.job.details.discussion",
    UI_JOB_DETAILS_CUSTOMERREQUESTS                                             : "ui.job.details.customerrequests",
    UI_JOB_DETAILS_JOBDESCRIPTION                                               : "ui.job.details.jobdescription",
    UI_JOB_DETAILS_ABNORMALITIES                                                : "ui.job.details.abnormalities",
    UI_JOB_DETAILS_OLDPARTSTATUS                                                : "ui.job.details.oldpartstatus",                  
    UI_JOB_DETAILS_CARPLATENUMBER                                               : "ui.job.details.carplatenumber",
    UI_JOB_DETAILS_CHECKOUT                                                     : "ui.job.details.checkout",
    UI_JOB_DETAILS_TRANSACTIONINVOICENUMBER                                     : "ui.job.details.transactioninvoicenumber",
    UI_JOB_DETAILS_CARMODEL                                                     : "ui.job.details.carModel",
    UI_JOB_DETAILS_MAINTENANCE                                                  : "ui.job.details.maintenance",
    UI_JOB_DETAILS_REPAIR                                                       : "ui.job.details.repair",
    UI_JOB_DETAILS_TAGCATEGORY                                                  : "ui.job.details.tagcategory",
    UI_JOB_DETAILS_TAGNAME                                                      : "ui.job.details.tagname",
    UI_JOB_DETAILS_SELECTTECHNICIANS                                            : "ui.job.details.selecttechnicians",
    UI_JOB_DETAILS_CHECKLIST1                                                   : "ui.job.details.checklist1",
    UI_JOB_DETAILS_CHECKLIST2                                                   : "ui.job.details.checklist2",
    UI_JOB_DETAILS_CHECKLIST3                                                   : "ui.job.details.checklist3",
    UI_JOB_DETAILS_CHECKLIST4                                                   : "ui.job.details.checklist4",
    UI_JOB_DETAILS_CHECKLIST5                                                   : "ui.job.details.checklist5",
    UI_JOB_DETAILS_CHECKLIST6                                                   : "ui.job.details.checklist6",
    UI_JOB_MODAL_KEEP                                                           : "ui.job.modal.keep",
    UI_JOB_MODAL_DISCARD                                                        : "ui.job.modal.discard",
    UI_JOB_MODAL_NOTAPPLICABLE                                                  : "ui.job.modal.notapplicable",
    UI_JOB_MODAL_OLDPARTS                                                       : "ui.job.modal.oldparts",
    UI_JOB_MODAL_ADDJOB                                                         : "ui.job.modal.addjob",
    UI_JOB_MODAL_NEWCUSTOMER                                                    : "ui.job.modal.newcustomer",
    UI_JOB_MODAL_NEWCUSTOMERVEHICLE                                             : "ui.job.modal.newcustomervehicle",
    UI_JOB_ATTACHMENT_UPLOADIMAGE                                               : "ui.job.attachment.uploadimage",
    UI_JOB_ATTACHMENT_NOATTACHMENTS                                             : "ui.job.attachment.noattachments",
    UI_JOB_ATTACHMENT_DELETE                                                    : "ui.job.attachment.delete",
    UI_JOB_ATTACHMENT_ENSURE                                                    : "ui.job.attachment.ensure",
    UI_JOB_ATTACHMENT_ATTACHMENTRULES1                                          : "ui.job.attachment.attachmentrules1",
    UI_JOB_ATTACHMENT_ATTACHMENTRULES2                                          : "ui.job.attachment.attachmentrules2",
    UI_JOB_SERVICEHISTORY_HEADER                                                : "ui.job.servicehistory.header",
    UI_CUSTOMERVEHICLE_HEADER                                                   : "ui.customervehicle.header",
    UI_CUSTOMERVEHICLE_MESSAGE                                                  : "ui.customervehicle.message",
    UI_CUSTOMERVEHICLE_DETAILS_CUSTOMERINFORMATION                              : "ui.customervehicle.details.customerinformation",
    UI_CUSTOMERVEHICLE_DETAILS_CUSTOMERVEHICLEINFORMATION                       : "ui.customervehicle.details.customervehicleinformation",
    UI_INCIDENT_DETAILS_HEADER                                                  : "ui.incident.details.header",
    UI_INCIDENT_DETAILS_CUSTOMER                                                : "ui.incident.details.customer",
    UI_INCIDENT_DETAILS_SUPPLIER                                                : "ui.incident.details.supplier",
    UI_INCIDENT_DETAILS_VEBO                                                    : "ui.incident.details.vebo",
    UI_INCIDENT_MODAL_NEWINCIDENT                                               : "ui.incident.modal.newincident",
    UI_INCIDENT_MODAL_CREATEINCIDENT                                            : "ui.incident.modal.createincident"
}