import AuthenticationSlice from "./AuthenticationSlice";
import ProfileSlice from "./ProfileSlice";
import ConfirmedTodayBookingsSlice from "./ConfirmedTodayBookingsSlice";
import EmployeesSlice from "./EmployeesSlice";
import VehiclesSlice from "./VehiclesSlice";
import CustomerVehicleSlice from "./CustomerVehicleSlice"
import JobSlice from "./JobSlice"
import CheckedInJobsSlice from "./CheckedInJobsSlice";
import StartedJobsSlice from "./StartedJobsSlice";
import CompletedJobsSlice from "./CompletedJobsSlice";
import TagsSlice from "./TagsSlice";

export {AuthenticationSlice, ProfileSlice, ConfirmedTodayBookingsSlice, EmployeesSlice, VehiclesSlice, CustomerVehicleSlice, JobSlice, CheckedInJobsSlice, StartedJobsSlice, CompletedJobsSlice, TagsSlice};