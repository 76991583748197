import "./Profile.css";
import React, {useCallback, useEffect,useState} from 'react';
import Text from '../../constants/Text';
import PATH from '../../constants/Path';
import ENDPOINT from '../../constants/API';
import Roles from "../../constants/Roles";
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Breadcrumb, Container, Form, Grid, GridColumn, Image, Segment, GridRow, Message, Header, Icon, FormGroup, FormInput, FormSelect, FormField } from 'semantic-ui-react'
import { Navigation } from '..';
import { useTranslation } from 'react-i18next';
import { getProfile, updateProfile, resetSuccess } from '../../redux-slice/ProfileSlice'
import { setAuthorization } from '../../redux-slice/AuthenticationSlice'
import { DateInput } from 'semantic-ui-calendar-react-17';
import { DATE_FORMAT } from '../../constants/Global'

export default function Profile () {

    const authentication = useSelector(state => state.authentication);
    const profile = useSelector(state => state.profile);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [profileEditMode, setProfileEditMode] = useState(false);
    const [passwordEditMode, setPasswordEditMode] = useState(false);
    const [updateGender, setSelectedGender] = useState({key:null,value:null});
    const [updateBirthday, setSelectedBirthday] = useState(null);
    const [viewPassword, setViewPassword] = useState(false);

    //For Change Password
    const [cp_loading, setCP_Loading] = useState(false);
    const [cp_message, setCP_Message] = useState(null);
    const [cp_success, setCP_Success] = useState(false);
    const [cp_error, setCP_Error] = useState(false);

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        }
        else
        {
            //START ON MOUNT DATA LOAD
            dispatch(getProfile());
        }
    },[authentication,dispatch,navigate]);

    useEffect(() => {
        onMount()
    },[onMount]);

    useEffect(() => {
        document.title = "Profile";
    });

    useEffect(() => {
        if(profile.data.gender !== null && updateGender.key === null)
        {
            updateGender.key = profile.data.gender.key;
            updateGender.value = profile.data.gender.value;
        }

        if(profile.data.birthday !== null && updateBirthday === null)
        {
            setSelectedBirthday(profile.data.birthday);
        }
    },[profile,updateGender,updateBirthday])

    const handleUpdateProfileSubmit = (event) => {
        event.preventDefault();
        const updateProfileData = Object.fromEntries(new FormData(event.target).entries());

        if(updateGender.key !== null && updateGender.value !== null)
        {
            updateProfileData.gender = updateGender;
        }

        if(updateBirthday !== null )
        {
            updateProfileData.birthday = updateBirthday;
        }

        dispatch(updateProfile(updateProfileData));
    };

    const handleSelectGender = (event,data) => {
        event.preventDefault();
        updateGender.key = data.value;
        updateGender.value = event.target.textContent;
        setSelectedGender(updateGender);
    }

    const handleSelectBirthday = (event,data) => {
        event.preventDefault();
        setSelectedBirthday(data.value);
    }

    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        setCP_Loading(true);

        const changePasswordData = Object.fromEntries(new FormData(event.target).entries());

        const response = await fetch(ENDPOINT.PASSWORD_ENDPOINT,{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(changePasswordData)
        })
        const body = await response.json();

        if(response.ok)
        {
            setCP_Success(true)
            setCP_Error(false)
            setCP_Message(body.message);
            setCP_Loading(false);
            dispatch(setAuthorization(credentialToBase64(profile.data.username,changePasswordData.newPassword)));
        }
        else
        {
            setCP_Error(true)
            setCP_Success(false)
            setCP_Message(body.message);
            setCP_Loading(false);
        }
    }
    
    const toggleEditProfileInformation = () => {
        dispatch(resetSuccess());
        setProfileEditMode(!profileEditMode);
    }

    const toggleEditPassword = () => {
        setPasswordEditMode(!passwordEditMode);
    }

    const toggleViewPassword = (event) => {
        if(viewPassword)
        {
            setViewPassword(false);
        }
        else
        {
            setViewPassword(true);
        }
    }

    const sections = [
        { key: t(Text.UI_NAVIGATION_DASHBOARD), content: t(Text.UI_NAVIGATION_DASHBOARD), href:PATH.DASHBOARD_PAGE },
        { key: t(Text.UI_SETTINGS_PROFILE_HEADER), content: t(Text.UI_SETTINGS_PROFILE_HEADER), link: false },
    ]

    const genderOptions = [
        { key: 'MALE', text: 'Male', value: 'MALE' },
        { key: 'FEMALE', text: 'Female', value: 'FEMALE' },
    ]
    
    const profileInformationView = () => {
        if(profileEditMode){
            return(
                <Form className="ui form" onSubmit={handleUpdateProfileSubmit} error={profile.error} success={profile.success}>
                    <FormGroup widths='equal'>
                        <FormInput required label={t(Text.GENERIC_DATA_PROFILE_FIRSTNAME)} type="text" name="firstName" placeholder={t(Text.GENERIC_DATA_PROFILE_FIRSTNAME)} defaultValue={profile.data.firstName}/>
                        <FormInput required label={t(Text.GENERIC_DATA_PROFILE_LASTNAME)} type="text" name="lastName" placeholder={t(Text.GENERIC_DATA_PROFILE_LASTNAME)} defaultValue={profile.data.lastName}/>
                    </FormGroup>
                    <FormGroup widths='equal'>
                        <FormInput required label={t(Text.GENERIC_DATA_PROFILE_MOBILENUMBER)} type="text" name="mobileNumber" placeholder={t(Text.GENERIC_DATA_PROFILE_MOBILENUMBER)} defaultValue={profile.data.mobileNumber}/>
                        <FormSelect label={t(Text.GENERIC_DATA_PROFILE_GENDER)} fluid defaultValue={profile.data.gender?profile.data.gender.key:null} options={genderOptions} placeholder={t(Text.GENERIC_DATA_PROFILE_GENDER)} onChange={handleSelectGender}/>
                    </FormGroup>
                    <FormField>
                        <label>{t(Text.GENERIC_DATA_PROFILE_BIRTHDAY)}</label>
                        <DateInput hideMobileKeyboard={true} dateFormat={DATE_FORMAT} value={updateBirthday?updateBirthday:""} placeholder="d-M-yyyy" iconPosition="left" onChange={handleSelectBirthday}/>
                    </FormField>
                    <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                    <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={profile.errorMessage}/>
                    <Message className='support-line-break' success header={t(Text.GENERIC_MESSAGE_HEADER_SUCCESS)}/>
                </Form>
            )
        }
        else{
            return(
                <div>
                   <h4 className='no-margin'>{t(Text.GENERIC_DATA_PROFILE_USERNAME)}/{t(Text.GENERIC_DATA_PROFILE_EMAIL)}:</h4>
                   <p className='no-margin'>{profile.data.username}</p>
                   <br/>
                   <h4 className='no-margin'>{t(Text.GENERIC_DATA_PROFILE_MOBILENUMBER)}:</h4>
                   <p className='no-margin'>{profile.data.mobileNumber}</p>
                   <br/>
                   <h4 className='no-margin'>{t(Text.GENERIC_DATA_PROFILE_BIRTHDAY)}:</h4>
                   <p className='no-margin'>{profile.data.birthday}</p>
                   <br/>
                   <h4 className='no-margin'>{t(Text.GENERIC_DATA_PROFILE_GENDER)}:</h4>
                   <p className='no-margin'>{profile.data.gender?.value}</p>
               </div>
           )
        }
    }

    const changePasswordView = () => {
        if(passwordEditMode){
            return(
                <Form loading={cp_loading} className="ui form" onSubmit={handleChangePasswordSubmit} success={cp_success} error={cp_error}>
                    <FormInput required label={t(Text.GENERIC_DATA_CHANGEPASSWORD_CURRENTPASSWORD)} type={viewPassword?"text":"password"} name="currentPassword" placeholder={t(Text.UI_SETTINGS_PROFILE_CURRENTPASSWORD)}/>
                    <FormInput required label={t(Text.GENERIC_DATA_CHANGEPASSWORD_NEWPASSWORD)} type={viewPassword?"text":"password"} name="newPassword" placeholder={t(Text.UI_SETTINGS_PROFILE_NEWPASSWORD)}/>
                    <FormInput required label={t(Text.GENERIC_DATA_CHANGEPASSWORD_CONFIRMNEWPASSWORD)} icon={<Icon name='eye' onClick={toggleViewPassword} link/>} type={viewPassword?"text":"password"} name="confirmNewPassword" placeholder={t(Text.UI_SETTINGS_PROFILE_CONFIRMNEWPASSWORD)} />
                    <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                    <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={cp_message}/>
                    <Message className='support-line-break' success header={t(Text.GENERIC_MESSAGE_HEADER_SUCCESS)}content={cp_message}/>
                </Form>
            )
        }
        else
        {
            return(
                <Header textAlign="center">Please click on the edit button to change password</Header>
            )
        }
    }

    const editProfileInformationButton = () => {
        if(profileEditMode){
            return <Button className="header-button" circular size='small' icon='close' onClick={()=>toggleEditProfileInformation()}/>
        }
        else{
            return <Button className="header-button" circular size='small' icon='pencil' onClick={()=>toggleEditProfileInformation()}/>
        }
    }

    const editPasswordButton = () => {
        if(passwordEditMode){
            return <Button className="header-button" circular size='small' icon='close' onClick={()=>toggleEditPassword()}/>
        }
        else{
            return <Button className="header-button" circular size='small' icon='pencil' onClick={()=>toggleEditPassword()}/>
        }
    }

    return (
        <Navigation headerText={t(Text.UI_SETTINGS_HEADER)} rolesAllowed={[Roles.ROLE_TECHNICIAN,Roles.ROLE_ADVISOR,Roles.ROLE_MANAGER]}>
            <Container textAlign='center'>
                <Segment className='primary-segment' loading={profile.loading}>
                    <Container textAlign='left'>
                        <Breadcrumb className="breadcrumb" icon='right angle' sections={sections} />
                    </Container>
                    <Grid stackable divided='vertically'>
                        <GridRow>
                            <GridColumn width={6}>
                                <Container>
                                    <Image src={profile.data.picture} size='small' centered circular/>
                                </Container>
                            </GridColumn>      
                            <GridColumn width={10} verticalAlign='middle'>                               
                                <Container>
                                    <h2 className='no-margin'>{profile.data.firstName?.toUpperCase()} {profile.data.lastName?.toUpperCase()}</h2>
                                    <h3 className='no-margin'>{profile.data.roles?profile.data.roles[0]:""}</h3>
                                </Container>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={16}>
                                <p className='primary-header no-margin'>{t(Text.UI_SETTINGS_PROFILE_INFORMATION)}{editProfileInformationButton()}</p>
                                <Container className="profile-information-container" textAlign='left'>
                                    {profileInformationView()}
                                </Container>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={16}>
                                <p className='primary-header no-margin'>{t(Text.UI_SETTINGS_PROFILE_CHANGEPASSWORD)}{editPasswordButton()}</p>
                                <Container className="change-password-container" textAlign='left'>
                                    {changePasswordView()}
                                </Container>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </Container>
        </Navigation>
    );
}

function credentialToBase64(username, password){
    return "basic " + Buffer.from(username+":"+password).toString('base64');
}