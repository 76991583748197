import "./TimerFlip.css"
import React, { useRef, useEffect, useState } from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

export const TimerFlip = ({value, tickView, format=["Day", "Hour", "Minute", "Second"], labels = ["Days", "Hours", "Minutes", "Seconds"]}) => {
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState();

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = tick => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
        value,
        didInit,
        credits : false
    });
    return () => Tick.DOM.destroy(tickValue);
  }, [value]);

  // Start the Tick.down process
  useEffect(() => {
    const counter = Tick.count.up(value, {
      format: format
    });

    // When the counter updates, update React's state value
    counter.onupdate = function(value) {
      setTickValue(value.map((n,index) => {
        let container = {}
        container.value = n;
        container.label = labels[index];
        return container;
      }));
    };

    return () => {
      counter.timer?.stop();
    };
  }, [value, format, labels]);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = tickValue;
    }
  }, [tickValue]);

  return (
    <div ref={divRef} className="tick">
      <div data-repeat="true" data-layout="horizontal fit">
        <div className="tick-group">
            <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">
              <span data-view={tickView?tickView:"flip"}></span>
            </div>
            <span data-key="label" data-view="text" className="tick-label"></span>
        </div>
      </div>
    </div>
  );
};