import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import { AuthenticationSlice, ProfileSlice, ConfirmedTodayBookingsSlice, EmployeesSlice, VehiclesSlice, JobSlice, CheckedInJobsSlice, StartedJobsSlice, CompletedJobsSlice, TagsSlice } from '../../redux-slice';
import storage from 'redux-persist/lib/storage';
import CustomerVehicleSlice from '../../redux-slice/CustomerVehicleSlice';

const persistConfigAuth = {
  key: 'authentication',
  storage: storage,
}

const persistConfigProfile = {
  key: 'profile',
  storage: storage,
}

const persistConfigJob = {
  key: 'job',
  storage: storage,
}

const persistConfigEmployees = {
  key: 'employees',
  storage: storage,
}

const persistConfigVehicles = {
  key: 'vehicles',
  storage: storage,
}

const persistConfigCustomerVehicle = {
  key: 'customerVehicle',
  storage: storage,
}

const persistConfigTags = {
  key: 'tags',
  storage: storage,
}

const persistConfigConfirmedTodayBookings = {
  key: 'confirmedTodayBookings',
  storage: storage,
}

const persistConfigCheckedInJobsSlice = {
  key: 'checkedInJobs',
  storage: storage,
}

const persistConfigStartedJobsSlice = {
  key: 'startedJobs',
  storage: storage,
}

const persistConfigCompletedJobsSlice = {
  key: 'completedJobs',
  storage: storage,
}

const persistedAuthenticationSlice = persistReducer(persistConfigAuth, AuthenticationSlice);
const persistedProfileSlice = persistReducer(persistConfigProfile, ProfileSlice);
const persistedJobSlice = persistReducer(persistConfigJob, JobSlice)
const persistedEmployeesSlice = persistReducer(persistConfigEmployees, EmployeesSlice);
const persistedVehiclesSlice = persistReducer(persistConfigVehicles, VehiclesSlice);
const persistedCustomerVehicleSlice = persistReducer(persistConfigCustomerVehicle, CustomerVehicleSlice)
const persistConfigTagsSlice = persistReducer(persistConfigTags, TagsSlice);
const persistedConfirmedTodayBookingsSlice = persistReducer(persistConfigConfirmedTodayBookings, ConfirmedTodayBookingsSlice);
const persistedCheckedInJobsSlice = persistReducer(persistConfigCheckedInJobsSlice, CheckedInJobsSlice);
const persistedStartedJobsSlice = persistReducer(persistConfigStartedJobsSlice, StartedJobsSlice);
const persistedCompletedJobsSlice = persistReducer(persistConfigCompletedJobsSlice, CompletedJobsSlice);

const store = configureStore({
  reducer: {
    authentication            : persistedAuthenticationSlice,
    profile                   : persistedProfileSlice,
    job                       : persistedJobSlice,
    employees                 : persistedEmployeesSlice,
    vehicles                  : persistedVehiclesSlice,
    customerVehicle           : persistedCustomerVehicleSlice,
    tags                      : persistConfigTagsSlice,
    confirmedTodayBookings    : persistedConfirmedTodayBookingsSlice,
    checkedInJobs             : persistedCheckedInJobsSlice,
    startedJobs               : persistedStartedJobsSlice,
    completedJobs             : persistedCompletedJobsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export default store;
export const persistor = persistStore(store)