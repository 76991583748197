import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, ButtonGroup, Checkbox, Dropdown, Form, FormCheckbox, Grid, GridColumn, GridRow, Icon, Label, LabelGroup, Message, Modal, ModalContent, ModalHeader, Pagination, Search, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import TagDropdown from '../common/dropdown/TagDropdown';
import ENDPOINT from '../../constants/API';
import Roles from '../../constants/Roles';
import RoleAllowed from '../common/RoleAllowed';
import NewProductModal from '../product/new/NewProductModal';
import UpdateProductModal from '../product/edit/UpdateProductModal';
import Text from '../../constants/Text';
import { useTranslation } from 'react-i18next';
import VisibleCondition from '../common/VisibleCondition';


export default function ProductSelectorModal ({quotationId, onAfterClose, trigger}) {
    const authentication = useSelector(state => state.authentication)
    const [open, setOpen] = useState(false);
    
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [selectedTag, setSelectedTag] = useState([]);
    const [searchValue, setSearchValue] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const [products, setProducts] = useState([]);
    const [pageable, setPageable] = useState();
    const [expandedProducts, setExpandedProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([])

    const [excludeNonBundle, setExcludeNonBundle] = useState(true);
    const [excludeBundle, setExcludeBundle] = useState(false);

    const pageSizeOptions = [
        {
            key: 5,
            text: "5",
            value: 5
        },
        {
            key: 10,
            text: "10",
            value: 10
        },
        {
            key: 25,
            text: "25",
            value: 25
        },
        {
            key: 50,
            text: "50",
            value: 50
        },
        {
            key: 100,
            text: "100",
            value: 100
        },
        {
            key: 200,
            text: "200",
            value: 200
        }
    ]

    const search = useCallback(async () => {
        setLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        var url = new URL(ENDPOINT.PRODUCT_ENDPOINT)
        url.searchParams.append("page", page);
        url.searchParams.append("size", pageSize);
        url.searchParams.append("excludeNonBundle", excludeNonBundle);
        url.searchParams.append("excludeBundle", excludeBundle);
        if(searchValue)
        {
            url.searchParams.append("search", "%"+searchValue+"%");
        }

        if(selectedTag)
        {
            selectedTag.forEach(tag => {
                url.searchParams.append("tags",tag)
            })
        }

        const response = await fetch(url,options)
        const body = await response.json();
    
        if(response.ok)
        {
            setProducts(body?.content);
            setPageable(body?.page);
        }
        setLoading(false)
    },[authentication.authorization, searchValue, selectedTag, page, pageSize, excludeNonBundle, excludeBundle]);

    const handleDeleteProduct = useCallback(async (id) => {
        setLoading(true);

        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        await fetch(ENDPOINT.PRODUCT_ENDPOINT + "/" + id, options)
        search();

        setLoading(false)
    },[authentication.authorization, search])

    const closeProductSelectorModal = useCallback(() => {
        setOpen(false);
        onAfterClose();
    },[onAfterClose])

    const handleAddProductsToQuote = useCallback(async () => {
        setLoading(true);

        const addProductsToQuoteForm = {};
        addProductsToQuoteForm.products = selectedProducts;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(addProductsToQuoteForm)
        }
    
        const response = await fetch(ENDPOINT.QUOTE_ENDPOINT + "/" + quotationId + "/addProducts", options)

        if(response.ok){
            closeProductSelectorModal();
        }
        else{
            const body = await response.json();
            setError(true);
            setErrorMessage(body?.message)
        }
        setLoading(false)
    },[authentication.authorization, quotationId, selectedProducts, closeProductSelectorModal])


    const openProductSelectorModal = () => {
        setOpen(true);
    }

    const handleOnSearchChange = (event,data) => {
        setSearchValue(data.value);
    }

    const toggleRowExpand = (index) => {
        const newExpandedProducts = [...expandedProducts];
        const currentIndexPosition = expandedProducts.indexOf(index);
        
        if (newExpandedProducts.includes(index)) 
        {
            newExpandedProducts.splice(currentIndexPosition, 1)
            setExpandedProducts(newExpandedProducts);
        } 
        else 
        {
            newExpandedProducts.push(index)
            setExpandedProducts(newExpandedProducts);
        }
    }

    const toggleProductSelection = (productId) => {
        let newSelectedProducts = [...selectedProducts]
        
        if(newSelectedProducts.includes(productId)){
            newSelectedProducts.splice(newSelectedProducts.indexOf(productId),1);
        }
        else {
            newSelectedProducts.push(productId)
        }
        setSelectedProducts(newSelectedProducts);
    }

    const renderProductBody = () => {
        var rows = [];
        
        products.forEach(product => {
            rows.push(
                <TableRow key={product.id}>
                    <TableCell collapsing><Checkbox checked={selectedProducts.includes(product?.id)} onChange={() => toggleProductSelection(product?.id)}/></TableCell>
                    <TableCell>{product?.bundle ? <Icon name={expandedProducts.includes(product.id) ? "caret down" : "caret right"} onClick={() => toggleRowExpand(product.id)} /> : null}{product?.sku}</TableCell>
                    <TableCell>{product?.name}</TableCell>
                    <TableCell>{product?.type}</TableCell>
                    <TableCell>RM {parseFloat(product?.costPrice)?.toFixed(2)}</TableCell>
                    <TableCell>{product?.supplier}</TableCell>
                    <TableCell>RM {parseFloat(product?.customerPrice)?.toFixed(2)}</TableCell>
                    <TableCell><LabelGroup>{product?.tags?.map(n => <Label key={n.id} content={n.name} />)}</LabelGroup></TableCell>
                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                        <TableCell>
                            <ButtonGroup>
                                <UpdateProductModal productId={product?.id} trigger={<Button icon="pencil"/>} onAfterClose={search}/>
                                <Button icon="trash" onClick={() => handleDeleteProduct(product?.id)} />
                            </ButtonGroup>
                        </TableCell>
                    </RoleAllowed>
                </TableRow>
            )

            if(expandedProducts.includes(product.id)){
                product?.products?.forEach(lineItem => {
                    rows.push(
                        <TableRow key={lineItem.id}>
                            <TableCell warning></TableCell>
                            <TableCell warning>{lineItem?.sku}</TableCell>
                            <TableCell warning>{lineItem?.name}</TableCell>
                            <TableCell warning>{lineItem?.type}</TableCell>
                            <TableCell warning>RM {parseFloat(lineItem?.costPrice)?.toFixed(2)}</TableCell>
                            <TableCell warning>{lineItem?.supplier}</TableCell>
                            <TableCell warning>RM {parseFloat(lineItem?.customerPrice)?.toFixed(2)}</TableCell>
                            <TableCell warning><LabelGroup>{lineItem?.tags?.map(n => <Label key={n.id} content={n.name} />)}</LabelGroup></TableCell>
                            <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                                <TableCell warning></TableCell>
                            </RoleAllowed>
                        </TableRow>
                    )
                })
            }
        })

        return rows;
    }

    useEffect(() => {
        if(selectedTag){
            search();
        }
    },[search,selectedTag])

    const toggleExcludeNonBundle = () => {
        if(excludeNonBundle){
            setExcludeNonBundle(false);
        }
        else {
            setExcludeNonBundle(true);
            setExcludeBundle(false);
        }
    }

    const toggleExcludeBundle = () => {
        if(excludeBundle){
            setExcludeBundle(false);
        }
        else {
            setExcludeBundle(true);
            setExcludeNonBundle(false);
        }
    }

    return (
        <>
            <Modal size='fullscreen' closeIcon="close" open={open} onClose={closeProductSelectorModal} onOpen={openProductSelectorModal} trigger={trigger} closeOnDimmerClick={false}>
                <ModalHeader>Select Products</ModalHeader>
                <ModalContent>
                    <Form>
                        <Grid stackable centered>
                            <GridRow columns={3}>
                                <GridColumn>
                                    <Search showNoResults={false} loading={loading} placeholder="Search" onSearchChange={handleOnSearchChange}/>
                                </GridColumn>
                                <GridColumn>
                                    <TagDropdown placeholder="Filter Tags" category={"PRODUCT"} value={selectedTag} handleSelectTag={(event,data) => setSelectedTag(data.value)}/>
                                </GridColumn>
                                <GridColumn>
                                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                                        <ButtonGroup stackable floated='right'>
                                            <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                                                <NewProductModal content={"New Product"} onAfterClose={search} />
                                            </RoleAllowed>
                                            <Button content={"Refresh"} icon="sync" loading={loading} onClick={() => search()}/>
                                            <Button disabled={selectedProducts.length === 0} content={"Add Selected Products"} color='green' icon="download" onClick={() => handleAddProductsToQuote()} />
                                        </ButtonGroup>
                                    </RoleAllowed>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={3}><FormCheckbox label={"Exclude Non Bundle"} toggle checked={excludeNonBundle} onChange={toggleExcludeNonBundle} /></GridColumn>
                                <GridColumn width={3}><FormCheckbox label={"Exclude Bundle"} toggle checked={excludeBundle} onChange={toggleExcludeBundle} /></GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn>
                                    <Table selectable celled definition>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHeaderCell />
                                                <TableHeaderCell>SKU</TableHeaderCell>
                                                <TableHeaderCell>Name</TableHeaderCell>
                                                <TableHeaderCell>Type</TableHeaderCell>
                                                <TableHeaderCell>Cost Price</TableHeaderCell>
                                                <TableHeaderCell>Supplier</TableHeaderCell>
                                                <TableHeaderCell>Customer Price</TableHeaderCell>
                                                <TableHeaderCell>Tags</TableHeaderCell>
                                                <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                                                    <TableHeaderCell>Actions</TableHeaderCell>
                                                </RoleAllowed>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {renderProductBody()}
                                        </TableBody>
                                    </Table>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn stretched width={2}>
                                    <Dropdown placeholder='Page Size' selection options={pageSizeOptions} onChange={(event, data) => {setPage(0); setPageSize(data?.value)}}/>
                                </GridColumn>
                                <GridColumn textAlign='right' width={14}>
                                    <Pagination 
                                        defaultActivePage={page + 1}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                        totalPages={pageable?.totalPages ? pageable?.totalPages : 0}
                                        onPageChange={(event, data) => {
                                            setPage(data.activePage - 1)
                                        }}
                                    />
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Form>
                    <VisibleCondition condition={error}>
                        <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                    </VisibleCondition>
                </ModalContent>
            </Modal>
        </>
    );
}
