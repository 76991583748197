import "./IncidentDetails.css";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import React, { useCallback, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import { Button, Confirm, Grid, GridColumn, GridRow, Header, Image, List, ListContent, ListItem, Message, Segment } from "semantic-ui-react";
import RoleAllowed from "../../common/RoleAllowed";
import ROLE from "../../../constants/Roles";
import UpdateIncidentPersonResponsibleModal from "./UpdateIncidentPersonResponsibleModal";
import UpdateIncidentCostModal from "./UpdateIncidentCostModal";
import UpdateIncidentReportModal from "./UpdateIncidentReportModal";
import UpdateIncidentCategoryModal from "./UpdateIncidentCategoryModal";
import UpdateIncidentResolvedModal from "./UpdateIncidentResolvedModal";

export default function IncidentDetails (props){

    const navigate = useNavigate();
    const {t} = useTranslation();
    const authentication = useSelector(state => state.authentication)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [incident, setIncident] = useState();
    const [confirmDelete, setConfirmDelete] = useState(false);

    const getIncident = useCallback(async () => {
        setLoading(true);
        setError(false);
        setErrorMessage(null);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/job/"+props?.job?.data?.id,options)
        const body = await response.json();

        if(response.ok)
        {
            setIncident(body)
        }
        else{
            setError(true);
            setErrorMessage(body.message);
        }
        setLoading(false)
    },[authentication.authorization,props.job])

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        } 
        else 
        {
            //START ON MOUNT DATA LOAD
            getIncident()
        }
    },[authentication,navigate,getIncident]);
    
    useEffect(() => {
        onMount();
    },[onMount]);

    const handleDeleteIncident = async(event) => {
        setLoading(true);
        event.preventDefault();

        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/"+incident?.id,options)

        if(response.ok)
        {
            window.location.reload();
        }
        else
        {
            const body = await response.json();
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    }


    const personResponsible = (incident) => {
        return(
            <List horizontal={isDesktop} className='no-margin' verticalAlign='middle'>
                {incident?.personResponsible?.map((value)=>(
                    <ListItem key={value.id}>
                        <Image avatar src={value.picture} />
                        <ListContent>{value.firstName+ " " + value.lastName}</ListContent>
                    </ListItem>
                    )
                )}
            </List>
        )
    }

    const openConfirmDeletePopUp = () => {
        setConfirmDelete(true);
    }

    const closeConfirmDeletePopUp = () => {
        setConfirmDelete(false);
    }

    return(
        <Segment basic >
            <Message hidden={!error} error={error} content={errorMessage}/>
            <Grid centered stackable padded>
                <GridRow>
                    <GridColumn width={16}>
                        <Grid>
                            <GridRow>
                                <GridColumn verticalAlign="bottom" width={7}>
                                    <Header>{t(Text.UI_INCIDENT_DETAILS_HEADER)}</Header>
                                </GridColumn>
                                <GridColumn width={9}>
                                    <Button floated="right" icon="trash" color="red" content="Delete Incident" onClick={openConfirmDeletePopUp}/>
                                    <Confirm open={confirmDelete} onCancel={closeConfirmDeletePopUp} onConfirm={handleDeleteIncident} content={"Click OK to DELETE the Incident or Cancel to close this pop-up"} header={"Deleting Incident"}/>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={16}>
                                    <Segment loading={loading}>
                                        <Grid stackable >
                                            <GridRow>
                                                <GridColumn width={4}>
                                                    <div>
                                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE)}
                                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                                {incident?<UpdateIncidentPersonResponsibleModal incident={incident} setIncident={setIncident}/>:null}      
                                                            </RoleAllowed>
                                                        </h4>
                                                        {personResponsible(incident)}
                                                    </div>
                                                </GridColumn>
                                                <GridColumn width={4}>
                                                    <div>
                                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_INCIDENT_CATEGORY)}
                                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                            {incident?<UpdateIncidentCategoryModal incident={incident} setIncident={setIncident}/>:null} 
                                                            </RoleAllowed>
                                                        </h4>
                                                        <span className="no-margin">{incident?.category?.value}</span>
                                                    </div>
                                                </GridColumn>
                                                <GridColumn width={4}>
                                                    <div>
                                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_INCIDENT_COST)}
                                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                                {incident?<UpdateIncidentCostModal incident={incident} setIncident={setIncident}/>:null} 
                                                            </RoleAllowed>
                                                        </h4>
                                                        <span className="no-margin">{"RM " + incident?.cost}</span>
                                                    </div>
                                                </GridColumn>
                                                <GridColumn width={4}>
                                                    <div>
                                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_INCIDENT_RESOLVED)}
                                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                            {incident?<UpdateIncidentResolvedModal incident={incident} setIncident={setIncident}/>:null} 
                                                            </RoleAllowed>
                                                        </h4>
                                                        <span className="no-margin">{incident?.resolved?"Yes":"No"}</span>
                                                    </div>
                                                </GridColumn>
                                            </GridRow>
                                            <GridRow>
                                                <GridColumn width={4}>
                                                    <div>
                                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_INCIDENT_REPORT)}
                                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                                {incident?<UpdateIncidentReportModal incident={incident} setIncident={setIncident}/>:null} 
                                                            </RoleAllowed>
                                                        </h4>
                                                        <p className="support-line-break">{incident?.report}</p>
                                                    </div>
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    )
}