import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, ModalContent, ModalDescription, Segment, Message, FormCheckbox } from 'semantic-ui-react';
import Text from '../../../constants/Text';

export default function UpdateProgressToCompletedModal (props) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);    
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
    
    //Checklist state
    const [item0Checked,setItem0Checked] = useState(false);
    const [item1Checked,setItem1Checked] = useState(false);
    const [item2Checked,setItem2Checked] = useState(false);
    const [item3Checked,setItem3Checked] = useState(false);
    const [item4Checked,setItem4Checked] = useState(false);
    const [item5Checked,setItem5Checked] = useState(false);
    //Checklist state

    useEffect(() => {

        if(item0Checked === true && item1Checked === true && item2Checked === true && item3Checked === true && item4Checked === true && item5Checked === true){
            setSubmitButtonEnabled(true);
        }
        else{
            setSubmitButtonEnabled(false);
        }
        
    },[item0Checked,item1Checked,item2Checked,item3Checked,item4Checked,item5Checked]);

    const handleSubmitCompleted = async(event) => {
        event.preventDefault();
        props.onClick(props.completedObject);
    }

    const updateProgressToCompletedModalButton = () => {
        return(
            <Button floated="right" primary>Completed</Button>
        )
    }

    const closeUpdateProgressToCompletedModal = () => {
        setItem0Checked(false);
        setItem1Checked(false);
        setItem2Checked(false);
        setItem3Checked(false);
        setItem4Checked(false);
        setItem5Checked(false);
        props.setError(false);
        props.setErrorMessage("");
        setOpen(false);
    }

    const openUpdateProgressToCompletedModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateProgressToCompletedModal} onOpen={openUpdateProgressToCompletedModal} trigger={updateProgressToCompletedModalButton()}>
            <Segment basic>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleSubmitCompleted}>
                            <h3>{"I CONFIRMED THAT I:"}</h3>
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST1)} checked={item0Checked} onChange={(e,data) => setItem0Checked(data.checked)} />
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST2)} checked={item1Checked} onChange={(e,data) => setItem1Checked(data.checked)} />
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST3)} checked={item2Checked} onChange={(e,data) => setItem2Checked(data.checked)} />
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST4)} checked={item3Checked} onChange={(e,data) => setItem3Checked(data.checked)} />
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST5)} checked={item4Checked} onChange={(e,data) => setItem4Checked(data.checked)} />
                            <FormCheckbox toggle label={t(Text.UI_JOB_DETAILS_CHECKLIST6)} checked={item5Checked} onChange={(e,data) => setItem5Checked(data.checked)} />
                            <Button className="vebo-submit-button" disabled={!submitButtonEnabled} fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error visible={props.error} header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={props.errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}