// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-bar {
    background-color: #eee!important;
}

.tab-simulate-fixed {
    height: 85vh!important;
    overflow-y: auto!important;
}

.progress-bar-row {
    padding-bottom:0em!important;
}

.progress-bar-label-row {
    padding-top:0em!important;
}

.button-margin {
    margin-left: 0.5em!important;;
}

.attachment-link{
    font-size: 1em!important;
}

.ui.accordion{
    background-color: white!important;
}

span.major {
    font-size: 1em;
    font-weight: bold;
}

span.minor {
    font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/job/details/JobDetails.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".menu-bar {\n    background-color: #eee!important;\n}\n\n.tab-simulate-fixed {\n    height: 85vh!important;\n    overflow-y: auto!important;\n}\n\n.progress-bar-row {\n    padding-bottom:0em!important;\n}\n\n.progress-bar-label-row {\n    padding-top:0em!important;\n}\n\n.button-margin {\n    margin-left: 0.5em!important;;\n}\n\n.attachment-link{\n    font-size: 1em!important;\n}\n\n.ui.accordion{\n    background-color: white!important;\n}\n\nspan.major {\n    font-size: 1em;\n    font-weight: bold;\n}\n\nspan.minor {\n    font-size: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
