const CRM_API_HOST = process.env.REACT_APP_CRM_API_HOST;
const EMPLOYEE_API = CRM_API_HOST + "/api/employee/v1"

module.exports = {
    FORGOT_PASSWORD_ENDPOINT                                      : CRM_API_HOST + "/guest/resetPassword/forgotPassword",
    PROFILE_ENDPOINT                                              : EMPLOYEE_API + "/profile",
    PASSWORD_ENDPOINT                                             : EMPLOYEE_API + "/password",     
    BOOKING_ENDPOINT                                              : EMPLOYEE_API + "/booking",     
    EMPLOYEE_ENDPOINT                                             : EMPLOYEE_API + "/employee",
    VEHICLE_ENDPOINT                                              : EMPLOYEE_API + "/vehicle",
    CUSTOMER_ENDPOINT                                             : EMPLOYEE_API + "/customer",
    CUSTOMER_VEHICLE_ENDPOINT                                     : EMPLOYEE_API + "/customerVehicle",
    JOB_ENDPOINT                                                  : EMPLOYEE_API + "/job",
    TAG_ENDPOINT                                                  : EMPLOYEE_API + "/tag",
    INCIDENT_ENDPOINT                                             : EMPLOYEE_API + "/incident",
    QUOTE_ENDPOINT                                                : EMPLOYEE_API + "/quote"
}