module.exports = {
    LOGIN_PAGE                                              : "/",
    FORGOT_PASSWORD_PAGE                                    : "/forgotpassword",
    LOGOUT_PAGE                                             : "/logout",
    DASHBOARD_PAGE                                          : "/dashboard",
    PROFILE_PAGE                                            : "/profile",
    JOB_PAGE                                                : "/job",
    JOB_DETAILS_PAGE                                        : "/job/:id/details",
    CUSTOMERVEHICLE_PAGE                                    : "/customervehicle",
    CUSTOMERVEHICLE_DETAILS_PAGE                            : "/customervehicle/:id/details", 
}