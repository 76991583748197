import { useCallback, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Form, FormCheckbox, FormInput, Grid, GridColumn, GridRow, Message, Modal, ModalContent, ModalHeader } from "semantic-ui-react"
import { useTranslation } from "react-i18next";
import TagDropdown from "../../common/dropdown/TagDropdown";
import Text from "../../../constants/Text";
import ENDPOINT from "../../../constants/API";
import CreateNewTagModal from "../../tag/new/CreateNewTagModal";
import ProductTypeDropdown from "../../common/dropdown/ProductTypeDropdown";
import ProductSupplierDropdown from "../../common/dropdown/ProductSupplierDropdown";
import VisibleCondition from "../../common/VisibleCondition"
import UpdateProductModal from "../edit/UpdateProductModal";

export default function NewProductModal ({content, onAfterClose}) {
    const {t} = useTranslation();
    const authentication = useSelector(state => state.authentication)

    const [open, setOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [copyParent, setCopyParent] = useState(false)
    const [bundle, setBundle] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [updateProductModal, setUpdateProductModal] = useState(null);

    const closeNewProductModal = useCallback(() => {
        setOpen(false);
        setBundle(false);
        setError(false);
        setErrorMessage(null);
        setSelectedTag([]);
        setSelectedType(null);
        setSelectedSupplier(null);
        setCopyParent(false);
        onAfterClose();
    },[onAfterClose])

    const handleCreateNewProductAction = useCallback(async (event) => {
        setLoading(true);
        event.preventDefault();
        const newProduct = Object.fromEntries(new FormData(event.target).entries());

        newProduct.tags = selectedTag
        newProduct.bundle = bundle;
        newProduct.type = selectedType;
        newProduct.supplier = selectedSupplier;
        newProduct.copyParent = copyParent;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(newProduct)
        }

        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            if(body.bundle){
                setUpdateProductModal(<UpdateProductModal key={Date.now()} productId={body?.id} showModal={true} onAfterClose={onAfterClose} />)
            }
            closeNewProductModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    },[closeNewProductModal, authentication.authorization, onAfterClose, bundle, selectedSupplier, selectedTag, selectedType, copyParent])

    const openNewProductModal = () => {
        setOpen(true);
    }

    const handleSelectProductType = (event, data) => {
        setSelectedType(data.value);
    }

    const handleSelectProductSupplier = (event, data) => {
        setSelectedSupplier(data.value);
    }

    return(
        <>
            <Modal centered closeIcon open={open} onClose={closeNewProductModal} onOpen={openNewProductModal} trigger={<Button content={content} icon="plus"/>} closeOnDimmerClick={false}>
                <ModalHeader>Create New {bundle ? "Bundle Product" : "Product"}</ModalHeader>
                <ModalContent>
                    <Grid>
                        <GridRow verticalAlign="middle" columns={2}>
                            <GridColumn>
                                <FormCheckbox toggle defaultChecked={bundle} name={"bundle"} label={"Bundle"} onChange={(event, data) => {setBundle(data.checked)}}/>
                            </GridColumn>
                            <GridColumn textAlign="right">
                                <CreateNewTagModal category={"PRODUCT"} trigger={<Button content={"New Tag"} className='button-margin' fluid={false} icon='plus'/>}/>
                            </GridColumn>
                        </GridRow>
                        <VisibleCondition condition={bundle}>
                            <GridRow>
                                <GridColumn>
                                    <FormCheckbox toggle defaultChecked={copyParent} name="copyParent" label={"Copy Parent"} onChange={(event, data) => {setCopyParent(data.checked)}} />
                                </GridColumn>
                            </GridRow>
                        </VisibleCondition>
                        <GridRow>
                            <GridColumn>
                                <Form onSubmit={handleCreateNewProductAction} loading={loading}>
                                    <FormInput name={"sku"} placeholder={"Input For Parts Only"} label={"SKU"} />
                                    <FormInput name={"name"} label={"Name"} />
                                    <ProductTypeDropdown form={true} clearable={true} label={"Type"} placeholder={"Select Type"} handleSelectProductType={handleSelectProductType}/>
                                    <VisibleCondition condition={!bundle}>
                                        <ProductSupplierDropdown form={true} clearable={true} label={"Supplier"} placeholder={"Select Supplier"} handleSelectProductSupplier={handleSelectProductSupplier}/>
                                        <FormInput placeholder={"00.00"} type="number" step="0.01" name={"customerPrice"} label={"Customer Price (RM)"} />
                                        <FormInput placeholder={"00.00"} type="number" step="0.01" name={"costPrice"} label={"Cost Price (RM)"}/>
                                    </VisibleCondition>
                                    <TagDropdown label={"Assign Tags"} category={"PRODUCT"} value={selectedTag} handleSelectTag={(event,data) => setSelectedTag(data.value)}/>
                                    <br/>
                                    <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                                    <Message visible={error} className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                                </Form>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </ModalContent>
            </Modal>
            {updateProductModal}
        </>
    )
}