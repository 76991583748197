import React, { useCallback, useEffect } from 'react'
import PATH from '../../../constants/Path';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getTagsList } from "../../../redux-slice/TagsSlice";
import { FormDropdown } from "semantic-ui-react";

export default function TagDropdown (props) {
    const authentication = useSelector(state => state.authentication);
    const tags = useSelector(state => state.tags);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        } 
        else 
        {
            //START ON MOUNT DATA LOAD
            dispatch(getTagsList());
        }
    },[authentication,dispatch,navigate]);

    useEffect(() => {
        onMount();
    },[onMount]);

    const tagListOptions = () =>{
        if(props.category){
            return tags.data?tags.data.filter(item => item.tagCategory.key === props.category).map(item => {
                let container = {};
                container['value'] = item.id;
                container['text'] = item.name;
                return container;
            }):[];
        }else{
            return tags.data?tags.data.map(item => {
                let container = {};
                container['value'] = item.id;
                container['text'] = item.name;
                return container;
            }):[];
        }
    } 

    return (
        <FormDropdown required={props.required} label={props.label} name={props.name} placeholder={props.placeholder} multiple fluid selection search options={tagListOptions()} onChange={props.handleSelectTag} defaultValue={props.defaultValue} />
    );
}
