import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, Form, FormCheckbox, FormInput, Grid, GridColumn, GridRow, Input, Label, Message, Modal, ModalContent, ModalHeader, Table, TableBody, TableCell, TableFooter, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ENDPOINT from "../../../constants/API"
import VisibleCondition from "../../common/VisibleCondition"
import ProductTypeDropdown from "../../common/dropdown/ProductTypeDropdown";
import ProductSupplierDropdown from "../../common/dropdown/ProductSupplierDropdown";
import ProductDropdown from "../../common/dropdown/ProductDropdown";
import CreateNewTagModal from "../../tag/new/CreateNewTagModal";
import TagDropdown from "../../common/dropdown/TagDropdown";
import Text from "../../../constants/Text";
import RoleAllowed from "../../common/RoleAllowed";
import Roles from "../../../constants/Roles";

export default function UpdateProductModal ({productId, trigger, showModal, onAfterClose}){
    const {t} = useTranslation();
    const authentication = useSelector(state => state.authentication)

    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [forceUpdate, setForceUpdate] = useState();
    const [changesMade, setChangesMade] = useState(false);

    const closeUpdateProductModal = useCallback(() => {
        onAfterClose();
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    },[onAfterClose])

    const openUpdateProductModal = () => {
        getProduct();
        setOpen(true);
    }

    const getProduct = useCallback(async () => {
        setError(false);
        setLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT + "/" + productId, options)
        const body = await response.json();

        if(response.ok)
        {
            setProduct(body);
            setForceUpdate(Date.now())
            setChangesMade(false);
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    },[authentication.authorization, productId])

    const handleUpdateProduct = useCallback(async (closeOnComplete) => {
        setError(false)
        setLoading(true);

        let productToUpdate = structuredClone(product);
        productToUpdate.tags = productToUpdate?.tags?.map(n => n?.id);
        productToUpdate?.products?.forEach(n => {
            n.tags = n?.tags?.map(n => n.id);;
        })
        
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(productToUpdate)
        }

        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT + "/" + productId, options)
        const body = await response.json();

        if(response.ok)
        {
            setProduct(body);
            setForceUpdate(Date.now())
            setChangesMade(false);
            if(closeOnComplete){
                closeUpdateProductModal();
            }
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    },[authentication.authorization, productId, product, closeUpdateProductModal])

    const handleAddLine = useCallback(async () => {
        setError(false)
        setLoading(true);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT + "/" + productId + "/addNewLineItem", options)
        const body = await response.json();

        if(response.ok)
        {
            setProduct(body)
            setForceUpdate(Date.now())
            setChangesMade(false);
        }
        setLoading(false)
    },[authentication.authorization,productId])

    const handleRemoveLine = useCallback(async (lineItemId) => {
        setLoading(true);

        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT + "/" + productId + "/deleteLineItem/" + lineItemId, options)
        const body = await response.json();

        if(response.ok)
        {
            setProduct(body)
            setForceUpdate(Date.now())
            setChangesMade(false);
        }
        setLoading(false)
    },[authentication.authorization,productId])

    useEffect(() => {
        if(showModal){
            setOpen(true);
        }
    },[showModal])

    useEffect(() => {
        if(productId){
            getProduct();
        }
    },[productId, getProduct])

    const setParentProductValue = (field, data) => {
        let productNew = structuredClone(product);
        if(field === "tags"){
            let tags = [];
            data?.value?.forEach(tagId => {
                tags.push(data?.options.find(option => option?.tag?.id === tagId)?.tag);
            })
            productNew.tags = tags;
        }
        else if(field === "copyParent"){
            productNew.copyParent = data?.checked;
        }
        else{
            productNew[field] = data?.value;
        }
        setProduct(productNew);
        setChangesMade(true);
    }

    const setChildProductValue = (id, field, data) => {
        let productNew = structuredClone(product);
        if(field === "tags"){
            let tags = [];
            data?.value?.forEach(tagId => {
                tags.push(data?.options.find(option => option?.tag?.id === tagId)?.tag);
            })
            productNew.products.find(n => n?.id === id).tags = tags;
        }
        else{
            productNew.products.find(n => n?.id === id)[field] = data?.value;
        }
        setProduct(productNew);
        setChangesMade(true);
    }

    const copyProductItemDetails = (id, field, data) => {
        let newBom = [...product?.products];
        const val = newBom.find(n => n.id === id);
        val[field] = data;
        
        let productToUpdate = {...product};
        productToUpdate.products = newBom;
        setProduct(productToUpdate);
        setChangesMade(true);
    }

    const handleSelectProductName = (id, data) => {
        let selectedProduct = data.options.find(n => n.key === data.value);
        copyProductItemDetails(id, "name", selectedProduct ? selectedProduct?.object?.name : data?.value);

        if(selectedProduct){
            copyProductItemDetails(id, "sku", selectedProduct?.object?.sku)
            copyProductItemDetails(id, "type", selectedProduct?.object?.type)
            copyProductItemDetails(id, "costPrice", selectedProduct?.object?.costPrice)
            copyProductItemDetails(id, "supplier", selectedProduct?.object?.supplier)
            copyProductItemDetails(id, "quantity", selectedProduct?.object?.quantity)
            copyProductItemDetails(id, "customerPrice", selectedProduct?.object?.customerPrice)
            copyProductItemDetails(id, "tags", selectedProduct?.object?.tags)
        }
        setForceUpdate(Date.now())
    }

    const renderBundleBody = () => {
        var rows = [];
        product?.products?.forEach(item => {
            rows.push(
                <TableRow key={item?.id}>
                    <TableCell key={forceUpdate} width={2}><Input fluid value={item?.sku} name={"sku"} onChange={(event, data) => setChildProductValue(item?.id, "sku", data)}/></TableCell>
                    <TableCell><ProductDropdown allowAdditions={true} value={item?.name} handleSelectProduct={(event, data) => handleSelectProductName(item?.id, data)}/></TableCell>
                    <TableCell><ProductTypeDropdown form={true} clearable={true} compact={true} value={item?.type} handleSelectProductType={(event,data) => setChildProductValue(item?.id, "type", data)}/></TableCell>
                    <TableCell><Input fluid label={"RM"} placeholder={"00.00"} value={item?.costPrice} type="number" step="0.01" name={"costPrice"} onChange={(event, data) => setChildProductValue(item?.id, "costPrice", data)}/></TableCell>
                    <TableCell><Input min={1} type="number" value={item?.quantity} fluid onChange={(event,data) => setChildProductValue(item?.id, "quantity", data)} /></TableCell>
                    <TableCell><ProductSupplierDropdown form={true} clearable={true} compact={true} value={item?.supplier} handleSelectProductSupplier={(event,data) => setChildProductValue(item?.id, "supplier", data)}/></TableCell>
                    <TableCell><Input fluid label={"RM"} placeholder={"00.00"} value={item?.addOnPrice} type="number" step="0.01" name={"addOnPrice"} onChange={(event, data) => setChildProductValue(item?.id, "addOnPrice", data)}/></TableCell>
                    <TableCell>{"RM " + item?.customerPrice}</TableCell>           
                    <TableCell width={4}><TagDropdown compact={true} value={item?.tags?.map(n => n?.id)} category={"PRODUCT"} handleSelectTag={(event,data) => setChildProductValue(item?.id, "tags", data)}/></TableCell>
                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                        <TableCell textAlign="center">
                            <ButtonGroup>
                                <Button icon="trash" onClick={() => handleRemoveLine(item?.id)} disabled={changesMade}/>
                            </ButtonGroup>
                        </TableCell>
                    </RoleAllowed>
                </TableRow>
            )
        })
        return rows;
    }

    return(
        <Modal size="fullscreen" centered closeIcon open={open} onClose={closeUpdateProductModal} onOpen={openUpdateProductModal} trigger={trigger} closeOnDimmerClick={false}>
            <ModalHeader>Update {product?.bundle ? "Bundle Product" : "Product"}</ModalHeader>
            <ModalContent>
                <Grid>
                    <GridRow>
                        <GridColumn>
                            <VisibleCondition condition={!product?.bundle}>
                                <Form loading={loading}>
                                    <FormInput name={"sku"} value={product?.sku} label={"SKU"} onChange={(event, data) => setParentProductValue("sku", data)}/>
                                    <FormInput name={"name"} value={product?.name} label={"Name"} onChange={(event, data) => setParentProductValue("name", data)}/>
                                    <ProductTypeDropdown form={true} clearable={true} label={"Type"} value={product?.type} placeholder={"Select Type"} handleSelectProductType={(event,data) => setParentProductValue("type", data)}/>
                                    <ProductSupplierDropdown form={true} clearable={true} label={"Supplier"} value={product?.supplier} placeholder={"Select Supplier"} handleSelectProductSupplier={(event,data) => setParentProductValue("supplier", data)}/>
                                    <FormInput placeholder={"00.00"} value={product?.customerPrice} type="number" step="0.01" name={"customerPrice"} label={"Customer Price (RM)"} onChange={(event, data) => setParentProductValue("customerPrice", data)}/>
                                    <FormInput placeholder={"00.00"} value={product?.costPrice} type="number" step="0.01" name={"costPrice"} label={"Cost Price (RM)"} onChange={(event, data) => setParentProductValue("costPrice", data)}/>
                                    <Grid stackable>
                                        <GridRow columns={2}>
                                            <GridColumn width={13}>
                                                <TagDropdown label={"Assign Tags"} value={product?.tags?.map(n => n.id)} category={"PRODUCT"} handleSelectTag={(event,data) => setParentProductValue("tags", data)}/>
                                            </GridColumn>
                                            <GridColumn width={3} verticalAlign="bottom" stretched>
                                                <CreateNewTagModal category={"PRODUCT"} trigger={<Button content={"New Tag"} className='button-margin' fluid={false} icon='plus'/>}/>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                    <br/>
                                    <Button onClick={() => {handleUpdateProduct(true)}} className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                                </Form>
                            </VisibleCondition>
                            <VisibleCondition condition={product?.bundle}>
                                <Form loading={loading}>
                                    <Grid centered stackable>
                                        <GridRow columns={3}>
                                            <GridColumn>
                                                <FormInput name={"sku"} value={product?.sku} label={"SKU"} onChange={(event, data) => setParentProductValue("sku", data)}/>
                                            </GridColumn>
                                            <GridColumn>
                                                <FormInput name={"name"} value={product?.name} label={"Name"} onChange={(event, data) => setParentProductValue("name", data)}/>
                                            </GridColumn>
                                            <GridColumn>
                                                <ProductTypeDropdown form={true} clearable={true} label={"Type"} value={product?.type} placeholder={"Select Type"} handleSelectProductType={(event,data) => setParentProductValue("type", data)}/>
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow verticalAlign="middle" columns={5}>
                                            <GridColumn>
                                                <TagDropdown label={"Assign Tags"} value={product?.tags?.map(n => n?.id)} category={"PRODUCT"} handleSelectTag={(event,data) => setParentProductValue("tags", data)}/>
                                            </GridColumn>
                                            <GridColumn verticalAlign="bottom" textAlign="left">
                                                <CreateNewTagModal category={"PRODUCT"} trigger={<Button content={"New Tag"} className='button-margin' fluid={false} icon='plus'/>}/>
                                            </GridColumn>
                                            <GridColumn>
                                                <FormCheckbox toggle checked={product?.copyParent} label={"Copy Parent"} onChange={(event, data) => setParentProductValue("copyParent", data)} />
                                            </GridColumn>
                                            <GridColumn>
                                                <Label tag color="black" size="huge" content="Cost Price Total" detail={"RM " + parseFloat(product?.costPrice)?.toFixed(2)}  />
                                            </GridColumn>
                                            <GridColumn>
                                                <Label tag color="black" size="huge" content="Customer Price Total" detail={"RM " + parseFloat(product?.customerPrice)?.toFixed(2)}  />
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow>
                                            <Table size="small" selectable celled textAlign="center">
                                                <TableHeader>
                                                    <TableRow textAlign="center">
                                                        <TableHeaderCell>SKU</TableHeaderCell>
                                                        <TableHeaderCell>Name</TableHeaderCell>
                                                        <TableHeaderCell>Type</TableHeaderCell>
                                                        <TableHeaderCell>Cost Price</TableHeaderCell>
                                                        <TableHeaderCell>Qty</TableHeaderCell>
                                                        <TableHeaderCell>Supplier</TableHeaderCell>
                                                        <TableHeaderCell>Add-On Price</TableHeaderCell>
                                                        <TableHeaderCell>Customer Price</TableHeaderCell>
                                                        <TableHeaderCell>Tags</TableHeaderCell>
                                                        <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER]}>
                                                            <TableHeaderCell>Actions</TableHeaderCell>
                                                        </RoleAllowed>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {renderBundleBody()}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TableCell colSpan='12'>
                                                            <Button color="black" loading={loading} disabled={loading || changesMade} icon="plus" basic fluid content={"Add New Line"} onClick={() => handleAddLine()}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </GridRow>
                                        <GridRow>
                                            <GridColumn textAlign="right">
                                                <ButtonGroup>
                                                    <Button onClick={() => handleUpdateProduct(true)} className="vebo-submit-button" size='large' type='submit'>Save & Close</Button>
                                                    <Button onClick={() => handleUpdateProduct(false)} size='large' color="green">Calculate & Save</Button>
                                                    <Button onClick={() => getProduct()}  size="large" color="black" content={"Reset"} />
                                                </ButtonGroup>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </Form>
                            </VisibleCondition>
                            <VisibleCondition condition={error}>
                                <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                            </VisibleCondition>
                            <VisibleCondition condition={false}>
                                {forceUpdate}
                            </VisibleCondition>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </ModalContent>
        </Modal>
    )
}