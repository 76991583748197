import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, FormCheckbox, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateIncidentResolvedModal (props) {

    const authentication = useSelector(state => state.authentication)

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [resolved, setResolved] = useState(props?.incident?.resolved);

    const handleUpdateIncidentResolvedModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateResolved = Object.fromEntries(new FormData(event.target).entries());
        updateResolved.resolved = resolved;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateResolved)
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/"+props?.incident?.id+"/updateResolved",options)
        const body = await response.json();

        if(response.ok)
        {
            props?.setIncident?.(body);
            setResolved(body.resolved);
            closeUpdateIncidentResolvedModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    }

    const updateIncidentResolvedButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateIncidentResolvedModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateIncidentResolvedModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateIncidentResolvedModal} onOpen={openUpdateIncidentResolvedModal} trigger={updateIncidentResolvedButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateIncidentResolvedModal} error={error}>
                            <FormCheckbox label={t(Text.GENERIC_DATA_INCIDENT_RESOLVED)} checked={resolved} onChange={(e,data) => setResolved(data.checked)} toggle/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}