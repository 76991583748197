import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import TagDropdown from '../../common/dropdown/TagDropdown';

export default function UpdateJobTagsModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedMaintenanceTags, setSelectedMaintenanceTags] = useState(props.job?.serviceData?.maintenanceTags?.map(item => item.id));
    const [selectedRepairTags, setSelectedRepairTags] = useState(props.job?.serviceData?.repairTags?.map(item => item.id));

    const handleUpdateJobTagModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateJobTag = Object.fromEntries(new FormData(event.target).entries());

        updateJobTag.maintenanceTags = selectedMaintenanceTags;
        updateJobTag.repairTags = selectedRepairTags;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateJobTag)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateTags",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobTagModal();
            dispatch(getJob({jobId:body.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const handleSelectTagMaintenance = (event,data) => {
        event.preventDefault();
        setSelectedMaintenanceTags(data.value);
    }

    const handleSelectTagRepair = (event,data) => {
        event.preventDefault();
        setSelectedRepairTags(data.value);
    }

    const updateJobTagModalButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobTagModal = () => {
        setSelectedMaintenanceTags(props.job?.serviceData?.maintenanceTags?.map(item => item.id));
        setSelectedRepairTags(props.job?.serviceData?.repairTags?.map(item => item.id));
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateJobTagModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobTagModal} onOpen={openUpdateJobTagModal} trigger={updateJobTagModalButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobTagModal} error={error}>
                            <TagDropdown name={'Repair'} label={t(Text.GENERIC_DATA_TAG_MAINTENANCE)} category={'MAINTENANCE'} defaultValue={selectedMaintenanceTags} placeholder={t(Text.GENERIC_DATA_TAG_MAINTENANCE_PLACEHOLDER)} handleSelectTag={handleSelectTagMaintenance}/>
                            <TagDropdown name={'Maintenance'} label={t(Text.GENERIC_DATA_TAG_REPAIR)} category={'REPAIR'} defaultValue={selectedRepairTags} placeholder={t(Text.GENERIC_DATA_TAG_REPAIR_PLACEHOLDER)} handleSelectTag={handleSelectTagRepair}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                        <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}