import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { removeEmployeeRole } from '../components/utils/RoleUtils';
import { BOOKING_ENDPOINT } from '../constants/API';
import { ROLE_ADVISOR, ROLE_MANAGER } from '../constants/Roles';

export const getConfirmedTodayBookings = createAsyncThunk(
    'confirmedTodayBookings/get',
    async (_data, thunkAPI) => {
      const role = removeEmployeeRole(thunkAPI.getState().profile.data.roles);
      if([ROLE_MANAGER,ROLE_ADVISOR].includes(role[0])){
        const response = await fetch(BOOKING_ENDPOINT+'/todayConfirmedBookings',{
          method: 'GET',
              headers: new Headers({
              'Authorization': thunkAPI.getState().authentication.authorization
          }),
          redirect: 'follow'
        })
    
        if(!response.ok){
          return thunkAPI.rejectWithValue((await response.json()));
        }
        else{
          return await response.json();
        }
      }
    }
)

const ConfirmedTodayBookingsSlice = createSlice({
    name:'confirmedTodayBookings',
    initialState:{
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },
    reducers: {
        resetSuccess: (state) => {
          state.success = false;
        }
    },
    extraReducers: (builder) => {
        //START GET CONFIRMED TODAY BOOKINGS REDUCERS
        builder.addCase(getConfirmedTodayBookings.pending, (state, _action) => {
          state.success = false;
          state.error = false;
          state.errorMessage = null;
          state.loading = true;
        })
        builder.addCase(getConfirmedTodayBookings.fulfilled, (state, action) => {
          if(action.payload)
          {
            state.data = action.payload;
            state.success = true;
            state.error = false;
            state.errorMessage = null;
            state.loading = false;
          }
        })
        builder.addCase(getConfirmedTodayBookings.rejected, (state, action) => {
          state.data = null;
          state.success = false;
          state.error = true;
          state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Confirmed Today Bookings";
          state.loading = false;
        })
        //END GET CONFIRMED TODAY BOOKINGS REDUCERS
    }
});  

export const { resetSuccess } = ConfirmedTodayBookingsSlice.actions;

export default ConfirmedTodayBookingsSlice.reducer;