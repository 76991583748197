import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, FormDropdown, FormInput, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import Text from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';

export default function CreateNewTagModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedTagCategory, setSelectedTagCategory] = useState(null);

    const tagCategoryOptions = [
        {
            key: 'MAINTENANCE',
            text: t(Text.UI_JOB_DETAILS_MAINTENANCE),
            value: 'MAINTENANCE'
        },
        {
            key: 'REPAIR',
            text: t(Text.UI_JOB_DETAILS_REPAIR),
            value: 'REPAIR'
        },
    ]

    const handleSelectTagCategory = (event,data) => {
        event.preventDefault();
        setSelectedTagCategory({key:data.value});
    }
    
    const handleCreateNewTagModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const createTag = Object.fromEntries(new FormData(event.target).entries());

        createTag.tagCategory = selectedTagCategory;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(createTag)
        }

        const response = await fetch(ENDPOINT.TAG_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeCreateNewTagModal();
            dispatch(getJob({jobId:props.job?.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobTagModalButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='plus'/>
        )
    }

    const closeCreateNewTagModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openCreateNewTagModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeCreateNewTagModal} onOpen={openCreateNewTagModal} trigger={updateJobTagModalButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleCreateNewTagModal} error={error}>
                            <FormDropdown required label={t(Text.UI_JOB_DETAILS_TAGCATEGORY)} name="tagCategory" placeholder="Choose Tag Category" fluid selection options={tagCategoryOptions} onChange={handleSelectTagCategory}/>
                            <FormInput required label={t(Text.UI_JOB_DETAILS_TAGNAME)} type='text' name="name" placeholder="Insert name for tag"/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}