import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateCustomerFirstNameModal (props) {
    
    const authentication = useSelector(state => state.authentication)
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleUpdateCustomerFirstNameModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCustomerFirstName = Object.fromEntries(new FormData(event.target).entries());
    
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCustomerFirstName)
        }
        const response = await fetch(ENDPOINT.CUSTOMER_ENDPOINT+"/"+props.customer?.id+"/updateFirstName",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateCustomerFirstNameModal();
            props.onUpdate();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateCustomerFirstNameButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateCustomerFirstNameModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateCustomerFirstNameModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateCustomerFirstNameModal} onOpen={openUpdateCustomerFirstNameModal} trigger={updateCustomerFirstNameButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateCustomerFirstNameModal} error={error}>
                            <FormInput required label={t(Text.GENERIC_DATA_CUSTOMER_FIRSTNAME)} type="text" name="firstName" placeholder={t(Text.GENERIC_DATA_CUSTOMER_FIRSTNAME)} defaultValue={props.customer?.firstName}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    )
}