import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { removeEmployeeRole } from '../components/utils/RoleUtils';
import { VEHICLE_ENDPOINT } from '../constants/API';
import { ROLE_ADVISOR, ROLE_MANAGER } from '../constants/Roles';

export const getVehiclesList = createAsyncThunk(
  'vehicles/get',
  async (_data, thunkAPI) => {
    const role = removeEmployeeRole(thunkAPI.getState().profile.data.roles);
    if([ROLE_MANAGER,ROLE_ADVISOR].includes(role[0])){
      const response = await fetch(VEHICLE_ENDPOINT+'/vehicles',{
        method: 'GET',
        headers: new Headers({
          'Authorization': thunkAPI.getState().authentication.authorization
        }),
        redirect: 'follow'
      })

      if(!response.ok){
        return thunkAPI.rejectWithValue((await response.json()));
      }
      else{
        return await response.json();
      }
    }
  }
)

const VehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {
      loading: false,
      error: false,
      success: false,
      errorMessage: null,
      data :null
    },
    reducers: {
      resetSuccess: (state) => {
        state.success = false;
      }
    },
    extraReducers: (builder) => {
      // START GET VEHICLE LIST REDUCERS
      builder.addCase(getVehiclesList.pending, (state, _action) => {
        state.success = false;
        state.error = false;
        state.errorMessage = null;
        state.loading = true;
      })
      builder.addCase(getVehiclesList.fulfilled, (state, action) => {
        state.data = action.payload;
        state.success = true;
        state.error = false;
        state.errorMessage = null;
        state.loading = false;
      })
      builder.addCase(getVehiclesList.rejected, (state, action) => {
        state.data = null;
        state.success = false;
        state.error = true;
        state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Vehicles List";
        state.loading = false;
      })
      // END VEHICLE LIST REDUCERS
      
    }
  });

  export const { resetSuccess } = VehiclesSlice.actions;
  
  export default VehiclesSlice.reducer;