import React, { useState } from 'react'
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormTextArea, Confirm } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";

export default function UpdateJobCustomerRequestModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [edited,setEdited] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false)

    const handleUpdateJobCustomerRequestModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCustomerRequest = Object.fromEntries(new FormData(event.target).entries());
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCustomerRequest)
        }
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateCustomerRequest",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobCustomerRequestModal();
            dispatch(getJob({jobId:body.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobCustomerRequestModal = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobCustomerRequestModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateJobCustomerRequestModal = () => {
        setOpen(true);
    }

    const changesMade = () => {
        setEdited(true);
    }

    const cancelConfirm = () => {
        setConfirmOpen(false)
    }

    const onConfirm = () => {
        setEdited(false)
        setConfirmOpen(false);
        closeUpdateJobCustomerRequestModal();
    }

    return(
        <>
            <Confirm open={confirmOpen} onCancel={cancelConfirm} onConfirm={onConfirm} confirmButton={"Discard"} header={"Confirm Close"} content={"You have modified this field. Would you like to discard your changes or cancel to continue editing?"}/>
            <Modal closeIcon open={open} onClose={edited?setConfirmOpen:closeUpdateJobCustomerRequestModal} onOpen={openUpdateJobCustomerRequestModal} trigger={updateJobCustomerRequestModal()} closeOnDimmerClick={false}>
                <Segment basic loading={loading}>
                    <ModalContent>
                        <ModalDescription>
                            <Form onSubmit={handleUpdateJobCustomerRequestModal} error={error}>
                                <FormTextArea rows={10} required label={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} type="text" name="customerRequest" placeholder={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} defaultValue={props.job?.customerRequest} onChange={changesMade}/>
                                <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                                <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                            </Form>
                        </ModalDescription>
                    </ModalContent>
                </Segment>
            </Modal>
        </>
    );
}