import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PROFILE_ENDPOINT } from '../constants/API';

export const getProfile = createAsyncThunk(
  'profile/get',
  async (_data, thunkAPI) => {
    const response = await fetch(PROFILE_ENDPOINT,{
      method: 'GET',
      headers: new Headers({
        'Authorization': thunkAPI.getState().authentication.authorization
      }),
      redirect: 'follow'
    })

    if(!response.ok){
      return thunkAPI.rejectWithValue((await response.json()));
    }
    else{
      return await response.json();
    }
  }
)

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (data, thunkAPI) => {
    const response = await fetch(PROFILE_ENDPOINT,{
      method: 'PATCH',
      headers: new Headers({
        'Authorization': thunkAPI.getState().authentication.authorization,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data),
      redirect: 'follow'
    })

    if(!response.ok){
      return thunkAPI.rejectWithValue((await response.json()));
    }
    else{
      return await response.json();
    }
  }
)

const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
      loading: false,
      error: false,
      success: false,
      errorMessage: null,
      data: null
    },
    reducers: {
      resetSuccess: (state) => {
        state.success = false;
      }
    },
    extraReducers: (builder) => {
      // START GET PROFILE REDUCERS
      builder.addCase(getProfile.pending, (state, _action) => {
        state.success = false;
        state.error = false;
        state.errorMessage = null;
        state.loading = true;
      })
      builder.addCase(getProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.success = true;
        state.error = false;
        state.errorMessage = null;
        state.loading = false;
      })
      builder.addCase(getProfile.rejected, (state, action) => {
        state.data = null;
        state.success = false;
        state.error = true;
        state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Profile Information";
        state.loading = false;
      })
      // END GET PROFILE REDUCERS
      // START UPDATE PROFILE REDUCERS
      builder.addCase(updateProfile.pending, (state, _action) => {
        state.success = false;
        state.error = false;
        state.errorMessage = null;
        state.loading = true;
      })
      builder.addCase(updateProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.success = true;
        state.error = false;
        state.errorMessage = null;
        state.loading = false;
      })
      builder.addCase(updateProfile.rejected, (state, action) => {
        state.data = null;
        state.success = false;
        state.error = true;
        state.errorMessage = action.payload.message?action.payload.message:"Unexpected Error Updating Profile Information";
        state.loading = false;
      })
      // END UPDATE PROFILE REDUCERS
    }
  });

  export const { resetSuccess } = ProfileSlice.actions;
  
  export default ProfileSlice.reducer;