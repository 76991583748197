import React, { useState } from 'react'
import Text, { UI_JOB_MODAL_NEWCUSTOMER } from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';
import VisibleCondition from '../../common/VisibleCondition';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput, ModalHeader } from 'semantic-ui-react';

export default function NewCustomerModal(props) {

    const authentication = useSelector(state => state.authentication)
    
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleNewCustomer = async(event) => {
        setLoading(true);
        event.preventDefault();
        const createNewCustomerVehicle = Object.fromEntries(new FormData(event.target).entries());

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(createNewCustomerVehicle)
        }

        const response = await fetch(ENDPOINT.CUSTOMER_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            if(props.handleCreateCustomer !== null){
                props.handleCreateCustomer(event,body)
            }
            closeNewCustomerModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const newCustomerButton = () => {
        return(
            <Button icon='add'/>
        )
    }

    const closeNewCustomerModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openNewCustomerModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeNewCustomerModal} onOpen={openNewCustomerModal} trigger={newCustomerButton()}>
            <ModalHeader>{t(UI_JOB_MODAL_NEWCUSTOMER)}</ModalHeader>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleNewCustomer} error={error}>
                            <FormInput required label={t(Text.GENERIC_DATA_PROFILE_FIRSTNAME)} type="text" name="firstName" placeholder={t(Text.GENERIC_DATA_PROFILE_FIRSTNAME)}/>
                            <FormInput required label={t(Text.GENERIC_DATA_PROFILE_LASTNAME)} type="text" name="lastName" placeholder={t(Text.GENERIC_DATA_PROFILE_FIRSTNAME)}/>
                            <FormInput required label={t(Text.GENERIC_DATA_PROFILE_MOBILENUMBER)} type="text" name="mobileNumber" placeholder={t(Text.GENERIC_DATA_PROFILE_MOBILENUMBER)}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} children={
                                <>
                                    {errorMessage}
                                    <VisibleCondition condition={errorMessage?.includes("Duplicate key, 'mobileNumber' of value ")}> 
                                       {"\n\nPlease do not create this Customer. Use the existing customer profile with the same mobile number by closing this screen and enter the mobile number to search for a customer in the previous screen."}
                                    </VisibleCondition>
                                </>
                            } />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}