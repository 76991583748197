import "./Navigation.css"
import React, { useState } from 'react';
import { Menu, Icon, Confirm, SidebarPushable, Sidebar, SidebarPusher, Header, Popup, Image } from 'semantic-ui-react';
import { useLocation, useNavigate} from 'react-router-dom';
import { LOGIN_PAGE, FORGOT_PASSWORD_PAGE, PROFILE_PAGE, DASHBOARD_PAGE, JOB_PAGE, CUSTOMERVEHICLE_PAGE} from '../../constants/Path';
import { UI_DASHBOARD_HEADER, UI_NAVIGATION_CUSTOMERVEHICLE, UI_NAVIGATION_DASHBOARD, UI_NAVIGATION_JOB, UI_NAVIGATION_LOGOUT, UI_NAVIGATION_MYPROFILE, UI_SETTINGS_PROFILE_HEADER, WARNING_HEADER, WARNING_LOGOUT_CONFIRMATION } from '../../constants/Text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RoleAllowed from './RoleAllowed'
import Roles from '../../constants/Roles'

export default function Navigation({children, headerText, rolesAllowed}) {

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [logoutConfirmation, setLogoutConfirmationOpen] = useState(false);
    const profile = useSelector(state => state.profile);
    
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const navigateToProfilePage = (event) => {
        event.preventDefault();
        if(headerText !== t(UI_SETTINGS_PROFILE_HEADER))
        {
            navigate(PROFILE_PAGE);
        }
    };

    const navigateToDashboardPage = (event) => {
        event.preventDefault();
        if(headerText !== t(UI_DASHBOARD_HEADER))
        {
            navigate(DASHBOARD_PAGE);
        }
    };

    const navigateToJobPage = (event) => {
        event.preventDefault();
        if(headerText !== t(UI_NAVIGATION_JOB))
        {
            navigate(JOB_PAGE);
        }
    };

    const navigateToCustomerVehiclePage = (event) => {
        event.preventDefault();
        if(headerText !== t(UI_NAVIGATION_CUSTOMERVEHICLE))
        {
            navigate(CUSTOMERVEHICLE_PAGE);
        }
    };

    const openLogoutConfirmation = () => {
        setLogoutConfirmationOpen(true);
    }

    const closeLogoutConfirmation = () => {
        setLogoutConfirmationOpen(false);
    }

    const userMenu = () => {
        if(!logoutConfirmation)
        {
            return <Popup className='popup' trigger={<Menu.Item><Icon name='user' inverted/></Menu.Item>} content={userMenuContent()} on='click' hideOnScroll/>
        }
    }

    const userMenuContent = () => {
        return (
            <Menu vertical borderless>
                <Menu.Item name={profile.data?.firstName + " " + profile.data?.lastName} style={{fontWeight:"bold"}}/>
                <Menu.Item icon='user' name={t(UI_NAVIGATION_MYPROFILE)} onClick={navigateToProfilePage}/>
                <Menu.Item icon='log out' name={t(UI_NAVIGATION_LOGOUT)} onClick={openLogoutConfirmation}/>
            </Menu>
        )
    }

    if(location.pathname !== LOGIN_PAGE && location.pathname !== FORGOT_PASSWORD_PAGE)
    {
        return (
            <div>
                <Menu fixed="top" className="top-menu">
                    <Menu.Item onClick={() => setSidebarVisible(true)}><Icon name='bars' inverted/></Menu.Item>
                    <Header className="top-menu-header" inverted>{headerText}</Header>
                    <Menu.Menu position='right'>{userMenu()}</Menu.Menu>
                </Menu>
                <SidebarPushable>
                    <Sidebar className="sidebar" as={Menu} vertical inverted animation='push' icon='labeled'  onHide={() => setSidebarVisible(false)} visible={sidebarVisible}>
                        <Image src='https://res.cloudinary.com/vebo-connect/image/upload/vebo_logo_FA_kdzmrw.png' size='medium' centered/> 
                        <Menu.Item as='a' style={{textAlign:"left"}} onClick={navigateToDashboardPage} content={t(UI_NAVIGATION_DASHBOARD)} />
                        <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER,Roles.ROLE_ADVISOR]}>
                            <Menu.Item as='a' style={{textAlign:"left"}} onClick={navigateToJobPage} content={t(UI_NAVIGATION_JOB)} />
                        </RoleAllowed>
                        <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER,Roles.ROLE_ADVISOR]}>
                            <Menu.Item as='a' style={{textAlign:"left"}} onClick={navigateToCustomerVehiclePage} content={t(UI_NAVIGATION_CUSTOMERVEHICLE)} />
                        </RoleAllowed>
                    </Sidebar>
                    <SidebarPusher className="sidebar-pusher">
                            <Confirm header={t(WARNING_HEADER)} content={t(WARNING_LOGOUT_CONFIRMATION)} open={logoutConfirmation} onCancel={closeLogoutConfirmation} onConfirm={logout} />
                            <RoleAllowed rolesAllowed={rolesAllowed}>
                                {children}
                            </RoleAllowed>
                    </SidebarPusher>
                </SidebarPushable>
            </div>
        )
    }
}

function logout() {
    localStorage.clear();
    window.location.reload(false);
}