import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, ModalContent, ModalDescription, Segment, Message, FormInput } from 'semantic-ui-react';
import Text from '../../../constants/Text';

export default function UpdateJobProgressToCheckOutModal (props) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);    

    const handleUpdateJobProgressToCheckOutModal = async(event) => {
        event.preventDefault();
        const transactionNumber = Object.fromEntries(new FormData(event.target).entries()).transactionNumber;
        props.onClick(props.checkedOutObject,transactionNumber);
    }

    const updateJobProgressToCheckOutModal = () => {
        return(
            <Button floated="right" primary>{t(Text.UI_JOB_DETAILS_CHECKOUT)}</Button>
        )
    }

    const closeUpdateJobProgressToCheckOutModal = () => {
        props.setError(false);
        props.setErrorMessage("");
        setOpen(false);
    }

    const openUpdateJobJobProgressToCheckOutModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobProgressToCheckOutModal} onOpen={openUpdateJobJobProgressToCheckOutModal} trigger={updateJobProgressToCheckOutModal()}>
            <Segment basic>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobProgressToCheckOutModal}>
                            <FormInput required label={t(Text.UI_JOB_DETAILS_TRANSACTIONINVOICENUMBER)} name="transactionNumber"></FormInput>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error visible={props.error} header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={props.errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}