import ENDPOINT from "../constants/API"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCustomerVehicle = createAsyncThunk(
    'customerVehicle/get',
    async (data,thunkAPI) => {
        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT+'/'+data.customerVehicleId,{
            method: 'GET',
                headers: new Headers({
                'Authorization': thunkAPI.getState().authentication.authorization
            }),
            redirect: 'follow'
        })
    
        if(!response.ok) {
            return thunkAPI.rejectWithValue((await response.json()));
        }
        else {
            return await response.json();
        }
    }
)

const CustomerVehicleSlice = createSlice({
    name: "customerVehicle",
    initialState: {
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null
    },
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        // START GET CUSTOMER VEHICLE REDUCERS
        builder.addCase(getCustomerVehicle.pending, (state, _action) => {
            state.success = false;
            state.error = false;
            state.errorMessage = null;
            state.loading = true;
        })
        builder.addCase(getCustomerVehicle.fulfilled, (state, action) => {
            state.data = action.payload;
            state.success = true;
            state.error = false;
            state.errorMessage = null;
            state.loading = false;
        })
        builder.addCase(getCustomerVehicle.rejected, (state, action) => {
            state.data = null;
            state.success = false;
            state.error = true;
            state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Customer Vehicle Details";
            state.loading = false;
        })
        // END GET CUSTOMER VEHICLE REDUCERS
    }
});

export const { resetSuccess } = CustomerVehicleSlice.actions;

export default CustomerVehicleSlice.reducer;