import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput, FormTextArea, FormDropdown } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateCustomerAddressModal (props) {
    
    const authentication = useSelector(state => state.authentication)
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    let selectedState = props?.customer?.state;

    const handleUpdateCustomerAddressModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCustomerAddress = Object.fromEntries(new FormData(event.target).entries());
        updateCustomerAddress.state = selectedState;
    
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCustomerAddress)
        }
        const response = await fetch(ENDPOINT.CUSTOMER_ENDPOINT+"/"+props.customer?.id+"/updateAddress",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateCustomerAddressModal();
            props.onUpdate();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateCustomerAddressButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateCustomerAddressModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateCustomerAddressModal = () => {
        setOpen(true);
    }

    const stateOptions = [
        {
            key: 'JOHOR',
            text: 'Johor',
            value: 'JOHOR'
        },
        {
            key: 'KEDAH',
            text: 'Kedah',
            value: 'KEDAH'
        },
        {
            key: 'KELANTAN',
            text: 'Kelantan',
            value: 'KELANTAN'
        },
        {
            key: 'MELAKA',
            text: 'Melaka',
            value: 'MELAKA'
        },
        {
            key: 'NEGERISEMBILAN',
            text: 'Negeri Sembilan',
            value: 'NEGERISEMBILAN'
        },
        {
            key: 'PAHANG',
            text: 'Pahang',
            value: 'PAHANG'
        },
        {
            key: 'PULAUPINANG',
            text: 'Pulau Pinang',
            value: 'PULAUPINANG'
        },
        {
            key: 'PERAK',
            text: 'Perak',
            value: 'PERAK'
        },
        {
            key: 'PERLIS',
            text: 'Perlis',
            value: 'PERLIS'
        },
        {
            key: 'SABAH',
            text: 'Sabah',
            value: 'SABAH'
        },
        {
            key: 'SARAWAK',
            text: 'Sarawak',
            value: 'SARAWAK'
        },
        {
            key: 'SELANGOR',
            text: 'Selangor',
            value: 'SELANGOR'
        },
        {
            key: 'TERENGGANU',
            text: 'Terengganu',
            value: 'TERENGGANU'
        },
        {
            key: 'KUALALUMPUR',
            text: 'Kuala Lumpur',
            value: 'KUALALUMPUR'
        },
        {
            key: 'LABUAN',
            text: 'Labuan',
            value: 'LABUAN'
        },
        {
            key: 'PUTRAJAYA',
            text: 'Putrajaya',
            value: 'PUTRAJAYA'
        }
    ]

    return(
        <Modal closeIcon open={open} onClose={closeUpdateCustomerAddressModal} onOpen={openUpdateCustomerAddressModal} trigger={updateCustomerAddressButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateCustomerAddressModal} error={error}>
                            <FormTextArea label={t(Text.GENERIC_DATA_CUSTOMER_ADDRESSLINE1)} type="text" name="addressLine1" placeholder={t(Text.GENERIC_DATA_CUSTOMER_ADDRESSLINE1)} defaultValue={props.customer?.addressLine1}/>
                            <FormTextArea label={t(Text.GENERIC_DATA_CUSTOMER_ADDRESSLINE2)} type="text" name="addressLine2" placeholder={t(Text.GENERIC_DATA_CUSTOMER_ADDRESSLINE2)} defaultValue={props.customer?.addressLine2}/>
                            <FormInput label={t(Text.GENERIC_DATA_CUSTOMER_POSTCODE)} type="text" name="postcode" placeholder={t(Text.GENERIC_DATA_CUSTOMER_POSTCODE)} defaultValue={props.customer?.postcode}/>
                            <FormInput label={t(Text.GENERIC_DATA_CUSTOMER_CITY)} type="text" name="city" placeholder={t(Text.GENERIC_DATA_CUSTOMER_CITY)} defaultValue={props.customer?.city}/>
                            <FormDropdown fluid search selection options={stateOptions}  label={t(Text.GENERIC_DATA_CUSTOMER_STATE)} onChange={(event,data) => {selectedState = {key:data.value};}} placeholder={t(Text.GENERIC_DATA_CUSTOMER_STATE)} defaultValue={props.customer?.state?.key}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    )
}