import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, FormInput, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateIncidentCostModal (props) {

    const authentication = useSelector(state => state.authentication)

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [cost, setCost] = useState(props?.incident?.cost);

    const handleUpdateIncidentCostModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCost = Object.fromEntries(new FormData(event.target).entries());

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCost)
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/"+props?.incident?.id+"/updateCost",options)
        const body = await response.json();

        if(response.ok)
        {
            props?.setIncident?.(body);
            setCost(body.cost);
            closeUpdateIncidentCostModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    }

    const updateIncidentCostButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateIncidentCostModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateIncidentCostModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateIncidentCostModal} onOpen={openUpdateIncidentCostModal} trigger={updateIncidentCostButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateIncidentCostModal} error={error}>
                            <FormInput label={t(Text.GENERIC_DATA_INCIDENT_COST)} placeholder={"RM " + cost} type='number' step="any" name="cost" required={true} defaultValue={cost}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}