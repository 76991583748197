import { useSelector } from 'react-redux';
import { removeEmployeeRole } from '../utils/RoleUtils';

export default function RoleAllowed({children,rolesAllowed}) {

    const profile = useSelector(state => state.profile)
    const role = removeEmployeeRole(profile.data?.roles);

    if(role)
    {
        if(rolesAllowed.includes(role[0]))
        {
            return(children)
        }
    }
}