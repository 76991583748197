import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PROFILE_ENDPOINT } from '../constants/API';

export const authorize = createAsyncThunk(
  'authentication/authorize',
  async (_data, thunkAPI) => {
    const response = await fetch(PROFILE_ENDPOINT,{
      method: 'GET',
      headers: new Headers({
        'Authorization': thunkAPI.getState().authentication.authorization,
      }),
      redirect: 'follow'
    })

    if(!response.ok)
    {
      return thunkAPI.rejectWithValue((await response.json()));
    }
    else
    {
      return await response.json();
    }
  }
)

const AuthenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
      loading: false,
      authenticated: false,
      errorAuthenticating: false,
      authorization: null,
    },
    reducers: {
      setAuthorization: (state, authorization) => {
        state.authorization = authorization.payload;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(authorize.pending, (state, _action) => {
        state.loading = true;
      })
      builder.addCase(authorize.fulfilled, (state, _action) => {
        state.authenticated = true;
        state.errorAuthenticating = false;
        state.loading = false;
      })
      builder.addCase(authorize.rejected, (state, _action) => {
        state.authenticated = false;
        state.errorAuthenticating = true;
        state.loading = false;
      })
    }
  });

  export const { setAuthorization } = AuthenticationSlice.actions;
  
  export default AuthenticationSlice.reducer;