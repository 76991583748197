// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tick {
   font-size:1rem; white-space:nowrap; font-family:arial,sans-serif;
 }
 
 .tick-flip,.tick-text-inline {
   font-size:1em;
 }
 
 .tick-label {
   margin-top:0em;font-size:0.7em;
 }
 
 .tick-char {
   width:1.5em;
 }
 
 .tick-text-inline {
   display:inline-block;text-align:center;min-width:1em;
 }
 
 .tick-text-inline+.tick-text-inline {
   margin-left:-.325em;
 }
 
 .tick-group {
   margin:0 .5em;text-align:center;
 }
 
 .tick-text-inline {
   color: #595d63 !important; 
 }
 
 .tick-label {
   color: #595d63 !important; 
 }
 
 .tick-flip-panel-text-wrapper {
   line-height: 1.45 !important; 
 }
 
 .tick-flip {
   border-radius:0.12em !important; 
 }`, "",{"version":3,"sources":["webpack://./src/components/common/TimerFlip.css"],"names":[],"mappings":"AAAA;GACG,cAAc,EAAE,kBAAkB,EAAE,4BAA4B;CAClE;;CAEA;GACE,aAAa;CACf;;CAEA;GACE,cAAc,CAAC,eAAe;CAChC;;CAEA;GACE,WAAW;CACb;;CAEA;GACE,oBAAoB,CAAC,iBAAiB,CAAC,aAAa;CACtD;;CAEA;GACE,mBAAmB;CACrB;;CAEA;GACE,aAAa,CAAC,iBAAiB;CACjC;;CAEA;GACE,yBAAyB;CAC3B;;CAEA;GACE,yBAAyB;CAC3B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;GACE,+BAA+B;CACjC","sourcesContent":[".tick {\n   font-size:1rem; white-space:nowrap; font-family:arial,sans-serif;\n }\n \n .tick-flip,.tick-text-inline {\n   font-size:1em;\n }\n \n .tick-label {\n   margin-top:0em;font-size:0.7em;\n }\n \n .tick-char {\n   width:1.5em;\n }\n \n .tick-text-inline {\n   display:inline-block;text-align:center;min-width:1em;\n }\n \n .tick-text-inline+.tick-text-inline {\n   margin-left:-.325em;\n }\n \n .tick-group {\n   margin:0 .5em;text-align:center;\n }\n \n .tick-text-inline {\n   color: #595d63 !important; \n }\n \n .tick-label {\n   color: #595d63 !important; \n }\n \n .tick-flip-panel-text-wrapper {\n   line-height: 1.45 !important; \n }\n \n .tick-flip {\n   border-radius:0.12em !important; \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
