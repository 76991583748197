import "./CustomerVehicle.css";
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { Button, Container, Grid, GridColumn, GridRow, Input, List, ListContent, ListHeader, ListItem, Message, Segment } from "semantic-ui-react";
import { CUSTOMER_VEHICLE_ENDPOINT } from "../../constants/API";
import NewCustomerVehicleModal from './new/NewCustomerVehicleModal';
import Text from '../../constants/Text';
import Navigation from "../common/Navigation";
import Roles from '../../constants/Roles';
import PATH from '../../constants/Path';

export default function CustomerVehicle () {

    const authentication = useSelector(state => state.authentication);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const messageTitle = t(Text.UI_CUSTOMERVEHICLE_MESSAGE)

    const [loading,setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [page] = useState(0);
    const [size] = useState(10);
    const [customerVehicles, setCustomerVehicles] = useState([]);
    const [message, setMessage] = useState(messageTitle);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = "Customer Vehicle";
    });

    const customerVehiclesList = (customerVehicles) => {

        if(customerVehicles.length > 0)
        {
            return customerVehicles.map((customerVehicle, idx) => 
                <ListItem key={idx}>
                    <ListContent>
                        <ListHeader>
                            <a href={generatePath(PATH.CUSTOMERVEHICLE_DETAILS_PAGE,{id:customerVehicle.id})}>{customerVehicle.registrationNumber}</a>
                        </ListHeader>
                        <label>{t(Text.GENERIC_DATA_VEHICLE_TYPE)} - {customerVehicle.vehicleData.posId}</label>
                        <br/>
                        <label>{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} - {customerVehicle.vinNumber}</label>
                        <br/>
                        <label>{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMERNAME)} - {customerVehicle.customerData.firstName + " "} {customerVehicle.customerData.lastName?customerVehicle.customerData.lastName:""}</label>
                        <br/>
                        <label>{t(Text.GENERIC_DATA_CUSTOMER_MOBILENUMBER)} - {customerVehicle.customerData.mobileNumber}</label>
                    </ListContent>
                </ListItem>
            )
        }
        else {
            return (
                <ListItem>
                    <ListContent>
                        <ListHeader>
                            <h4>{message}</h4>
                            {error &&<Message className='support-line-break' error  header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />}
                        </ListHeader>
                    </ListContent>
                </ListItem>
            )
        }
    }

    const searchCustomerVehicles = async () => {
        setLoading(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        //Build URL
        const searchURL = new URL(CUSTOMER_VEHICLE_ENDPOINT+"/search");
        searchURL.searchParams.append("searchText",searchText)
        searchURL.searchParams.append("size",size)
        searchURL.searchParams.append("page",page)

        const response = await fetch(searchURL,options)
        const body = await response.json();
        if(response.ok)
        {
            setError(false)
            setCustomerVehicles(body)
            if(customerVehicles.length === 0){
                setMessage("No customer vehicles found");
            }
        }
        else
        {
            setErrorMessage(body.message)
            setError(true)
        }
        setLoading(false)
    }

    const handleCreateCustomerVehicle = async (event,body) => {
        navigate(PATH.CUSTOMERVEHICLE_DETAILS_PAGE.replace(":id",body.id))
    }

    return(
        <Navigation rolesAllowed={[Roles.ROLE_MANAGER,Roles.ROLE_ADVISOR]} headerText={t(Text.UI_CUSTOMERVEHICLE_HEADER)}>
            <Container>
                <Segment className='primary-segment' basic>
                    <Grid>
                        <GridRow>
                            <GridColumn textAlign="left" width={14}>
                                <Input icon={<Button icon='search' onClick={searchCustomerVehicles} />} onChange={(e,searchText) => {setSearchText(searchText.value)}}/>
                            </GridColumn>
                            <GridColumn width={2}>
                                <NewCustomerVehicleModal handleCreateCustomerVehicle={handleCreateCustomerVehicle}/>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <Segment textAlign="left" loading={loading}>
                                    <List divided relaxed>
                                        {customerVehiclesList(customerVehicles)}
                                    </List>
                                </Segment>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </Container>
        </Navigation>
    )
}