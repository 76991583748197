import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormTextArea } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import { Autosave } from 'react-autosave';

export default function UpdateJobAbnormalitiesModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [jobAbnormalities, setJobAbnormalities] = useState(props.job?.abnormalities);
    const [autosaveLabel, setAutosaveLabel] = useState("");

    const handleAutosaveUpdateJobDescriptionModal = async(event) => {
        setLoading(true);
        let container = {
            abnormalities:jobAbnormalities
        }

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(container)
        }

        try{
            const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateAbnormalities",options)
            const body = await response.json();

            if(response.ok)
            {
                setLoading(false)
                setAutosaveLabel("Autosaved at : " + new Date().toLocaleString())
            }
            else
            {
                setError(true)
                setErrorMessage(body.message)
                setLoading(false)
            }
        }
        catch(error){
            setLoading(false)
            setAutosaveLabel("Error Auto-Saving, check connection")
        }
    }

    const handleUpdateJobAbnormalities = async(event) => {
        setLoading(true);
        let container = {
            abnormalities:jobAbnormalities
        }

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(container)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateAbnormalities",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            setAutosaveLabel("")
            closeUpdateJobAbnormalitiesModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobAbnormalitiesButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobAbnormalitiesModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
        dispatch(getJob({jobId:props?.job?.id}))
    }

    const openUpdateJobAbnormalitiesModal = () => {
        setOpen(true);
    }

    return(
        <>
            <Modal closeIcon open={open} onClose={closeUpdateJobAbnormalitiesModal} onOpen={openUpdateJobAbnormalitiesModal} trigger={updateJobAbnormalitiesButton()} closeOnDimmerClick={false}>
                <Segment basic loading={loading}>
                    <ModalContent>
                        <ModalDescription>
                            <Form onSubmit={handleUpdateJobAbnormalities} error={error}>
                                <FormTextArea rows={10} required label={t(Text.UI_JOB_DETAILS_ABNORMALITIES)} type="text" name="abnormalities" placeholder={t(Text.UI_JOB_DETAILS_ABNORMALITIES)} defaultValue={props.job?.abnormalities} onChange={(event,data) => setJobAbnormalities(data.value)}/>
                                <p>{autosaveLabel}</p>
                                <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                                <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                                <Autosave interval={30000} data={jobAbnormalities} onSave={handleAutosaveUpdateJobDescriptionModal} />
                            </Form>
                        </ModalDescription>
                    </ModalContent>
                </Segment>
            </Modal>
        </>
    );
}