import "./JobDetails.css"
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';   
import { Confirm, List,Grid, GridColumn, GridRow, Header, ListItem, Segment, Container, Icon } from "semantic-ui-react";
import { convertDateTimeToTime } from '../../utils/DateTimeFormatUtil';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import DATEFORMAT from '../../../constants/Global';
import VisibleCondition from "../../common/VisibleCondition";
import UploadAttachmentModal from "./UploadAttachmentModal";
import { t } from "i18next";

export default function JobAttachments (){
    const navigate = useNavigate();
    const authentication = useSelector(state => state.authentication)
    const job = useSelector(state => state.job);

    const [loading, setLoading] = useState(true);
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [selectedAttachment, SetSelectedAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);
    
    const getAttachments = useCallback(async () => {
        setLoading(true)

        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+job.data.id+"/attachments",options)
        const body = await response.json();
        setAttachments(body)
        setLoading(false)
    },[authentication,job.data.id])

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        } 
        else 
        {
            //START ON MOUNT DATA LOAD
            getAttachments()
        }
    },[authentication,navigate,getAttachments]);
    
    useEffect(() => {
        onMount();
    },[onMount]);
  
    const handleDownloadAttachment = async(event,id,filename) => {
        event.preventDefault();
        setLoading(true)
        const options = {
            method: 'GET',
            headers: {
                'accept': 'application/octet-stream',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+job.data.id+"/attachment/"+id,options)
        response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          });
          setLoading(false)
    }

    const handleDeleteAttachment = async(value) => {
        setLoading(true)
        setDeleteMessage(false)
         const options = {
            method: 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        await fetch(ENDPOINT.JOB_ENDPOINT+"/"+job.data.id+"/removeAttachment/"+value.id,options)
        var newList = attachments.filter((item) => item.id !== value.id);
        setAttachments(newList)
        setDeleteMessage(false)
        setLoading(false)
    }

    const triggerDeleteAttachment = (value) => {
        setDeleteMessage(true);
        SetSelectedAttachment(value);
    }

    const updateAttachmentList=()=>{
        getAttachments()
    }

    const attachmentList = (item) => {
        const attachments = item?item:[];
        return attachments.map ((attachment,idx) =>
            <ListItem key={attachment.id}>
                <Grid padded>
                    <GridRow>
                        <GridColumn width={14}>
                            <a href="/#" className="no-margin attachment-link" onClick={(e) => handleDownloadAttachment(e,attachment.id,attachment.filename)}>{attachment.filename}</a>
                            <h4 className="no-margin">{convertDateTimeToTime(attachment.uploadDate, DATEFORMAT.DATE_TIME_FORMAT)}</h4>
                        </GridColumn>
                        <GridColumn width={2} textAlign="right" verticalAlign="middle">
                            <Icon link floated="right" name="trash alternate outline" onClick={() => triggerDeleteAttachment(attachment)}/>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </ListItem>
        )
    }
   
    return(
      <Segment loading={loading} basic>
            <Grid stackable padded centered>
                <GridRow>
                    <GridColumn>
                        <Container fluid textAlign="right">
                            <UploadAttachmentModal onRefresh={updateAttachmentList}  job={job}/>
                        </Container>
                        <Segment textAlign="left">
                            <VisibleCondition condition={attachments?.length===0}>
                                <h3>{t(Text.UI_JOB_ATTACHMENT_NOATTACHMENTS)}</h3>
                            </VisibleCondition>
                            <Header as="h3">
                                <List divided relaxed >
                                    {attachmentList(attachments)}
                                </List>
                                <Confirm
                                    content={t(Text.UI_JOB_ATTACHMENT_DELETE)}
                                    open={deleteMessage}
                                    onCancel={()=>setDeleteMessage(false)}
                                    onConfirm={()=>handleDeleteAttachment(selectedAttachment)}
                                />
                            </Header>
                        </Segment>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    )
}
