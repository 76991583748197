import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateCustomerVehicleVinNumberModal (props) {
    
    const authentication = useSelector(state => state.authentication)
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleUpdateCustomerVehicleVinNumberModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCustomerVehicleVinNumber = Object.fromEntries(new FormData(event.target).entries());
    
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCustomerVehicleVinNumber)
        }
        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT+"/"+props.customerVehicle?.id+"/updateVinNumber",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateCustomerVehicleVinNumberModal();
            props.onUpdate();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateCustomerVehicleVinNumberButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateCustomerVehicleVinNumberModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateCustomerVehicleVinNumberModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateCustomerVehicleVinNumberModal} onOpen={openUpdateCustomerVehicleVinNumberModal} trigger={updateCustomerVehicleVinNumberButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateCustomerVehicleVinNumberModal} error={error}>
                            <FormInput required label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} type="text" name="vinNumber" placeholder={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} defaultValue={props.customerVehicle?.vinNumber}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    )
}