import React, {useCallback, useEffect} from 'react'
import Text from '../../constants/Text';
import { Button, Form, FormInput, Grid, Image, Message, Segment } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE, FORGOT_PASSWORD_PAGE } from '../../constants/Path';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthorization, authorize} from '../../redux-slice/AuthenticationSlice'
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { getProfile } from '../../redux-slice/ProfileSlice';

export default function Login() {
  
  const authentication = useSelector(state => state.authentication);
  const profile = useSelector(state => state.profile);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onMount = useCallback(() => {
    if(authentication.authenticated && profile.data)
    {
      navigate(DASHBOARD_PAGE);
    }
  },[authentication,profile,navigate]);

  useEffect(() => {
    onMount();
  },[onMount]);

  useEffect(() => {
      document.title = "Login";
  });

  const handleSubmit = event => {
    event.preventDefault();
    const loginFormData = new FormData(event.target);
    basicAuth(loginFormData);
  };

  const basicAuth = (loginFormData) => {
    dispatch(setAuthorization(credentialToBase64(loginFormData.get('username'),loginFormData.get('password'))));
    dispatch(authorize());
    dispatch(getProfile());
  }

  const usernameInput = () => {
    if(authentication.username === null)
    {
      return <FormInput fluid icon='user' iconPosition='left' placeholder={t(Text.UI_LOGIN_PLACEHOLDER_USERNAME)} autoComplete='username' name="username" />
    }
    else
    {
      return <FormInput fluid icon='user' iconPosition='left' placeholder={t(Text.UI_LOGIN_PLACEHOLDER_USERNAME)} autoComplete='username' name="username" defaultValue={authentication.username} />
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 500, margin:30}}>
          <Image src='https://res.cloudinary.com/vebo-connect/image/upload/v1677483296/eJobsheet_trans.png' size='medium' centered/> 
          <Form size='large' error={authentication.errorAuthenticating} onSubmit={handleSubmit}>
          <Segment loading={authentication.loading}>
              {usernameInput()}
              <FormInput fluid icon='lock' iconPosition='left' placeholder={t(Text.UI_LOGIN_PLACEHOLDER_PASSWORD)} autoComplete='current-password' type='password' name="password" />
              <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.UI_LOGIN_BUTTON_LOGIN)}</Button>
              <Message>{t(Text.UI_LOGIN_MESSAGE_FORGOTPASSWORD_CONTENT)}<Link to={FORGOT_PASSWORD_PAGE}>{t(Text.UI_LOGIN_LINK_FORGOTPASSWORD)}</Link></Message>
              <Message className='support-line-break' error header={t(Text.UI_LOGIN_MESSAGE_WRONGCREDENTIALS_HEADER)} content={t(Text.UI_LOGIN_MESSAGE_WRONGCREDENTIALS_CONTENT)}/>
          </Segment>
          </Form>
      </Grid.Column>
    </Grid>
  );
}

function credentialToBase64(username, password){
  return "basic " + Buffer.from(username+":"+password).toString('base64');
}