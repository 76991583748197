import ENDPOINT from "../constants/API"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getJob = createAsyncThunk(
    'job/get',
    async (data,thunkAPI) => {
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+'/'+data.jobId,{
            method: 'GET',
                headers: new Headers({
                'Authorization': thunkAPI.getState().authentication.authorization
            }),
            redirect: 'follow'
        })
    
        if(!response.ok) {
            return thunkAPI.rejectWithValue((await response.json()));
        }
        else {
            return await response.json();
        }
    }
)

const JobSlice = createSlice({
    name: "job",
    initialState: {
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null
    },
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        // START GET JOB REDUCERS
        builder.addCase(getJob.pending, (state, _action) => {
            state.success = false;
            state.error = false;
            state.errorMessage = null;
            state.loading = true;
        })
        builder.addCase(getJob.fulfilled, (state, action) => {
            state.data = action.payload;
            state.success = true;
            state.error = false;
            state.errorMessage = null;
            state.loading = false;
        })
        builder.addCase(getJob.rejected, (state, action) => {
            state.data = null;
            state.success = false;
            state.error = true;
            state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving Job Details";
            state.loading = false;
        })
        // END GET JOB REDUCERS
    }
});

export const { resetSuccess } = JobSlice.actions;

export default JobSlice.reducer;