import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import EmployeeDropdown from '../../common/dropdown/EmployeeDropdown';

export default function UpdateIncidentPersonResponsibleModal (props) {

    const authentication = useSelector(state => state.authentication)

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedPersonResponsible, setSelectedPersonResponsible] = useState(props?.incident?.personResponsible);

    const handleUpdateIncidentPersonResponsibleModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updatePersonResponsible = Object.fromEntries(new FormData(event.target).entries());

        updatePersonResponsible.personResponsible = selectedPersonResponsible;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updatePersonResponsible)
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/"+props?.incident?.id+"/updatePersonResponsible",options)
        const body = await response.json();

        if(response.ok)
        {
            props?.setIncident?.(body);
            setSelectedPersonResponsible(body.personResponsible);
            closeUpdateIncidentPersonResponsibleModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    }

    const handleSelectPersonResponsible = (event,data) => {
        event.preventDefault();
        setSelectedPersonResponsible(data.value);
    }

    const updateIncidentPersonResponsibleButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateIncidentPersonResponsibleModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateIncidentPersonResponsibleModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateIncidentPersonResponsibleModal} onOpen={openUpdateIncidentPersonResponsibleModal} trigger={updateIncidentPersonResponsibleButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateIncidentPersonResponsibleModal} error={error}>
                            <EmployeeDropdown label={t(Text.GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE)} placeholder={t(Text.GENERIC_DATA_INCIDENT_PERSONRESPONSIBLE)} required={true} multiple={true} handleSelectEmployee={handleSelectPersonResponsible} defaultValue={selectedPersonResponsible?.map(item => item.id)}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}