import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormDropdown } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateJobCustomerVehicleModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();
    
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const [customerVehicleOptions, setCustomerVehicleOptions] = useState([])
    const [selectedCustomerVehicle, setSelectedCustomerVehicle] = useState(null);

    const handleOnSearchChange = async (event,value) => {
        setLoading(true);
        event.preventDefault();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT+"/search?searchText="+value.searchQuery,options)
        const body = await response.json();
        
        setCustomerVehicleOptions(body?body.map(item => {
            let container = {};
            container['value'] = item.id;
            container['text'] = item.registrationNumber;
            return container;
        }):[]);
        setLoading(false);
    }

    const handleUpdateJobCustomerVehicle = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateJobCustomerVehicle = Object.fromEntries(new FormData(event.target).entries());
        updateJobCustomerVehicle.customerVehicleId = selectedCustomerVehicle;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateJobCustomerVehicle)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateCustomerVehicle",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobCustomerVehicleModal();
            dispatch(getJob({jobId:body.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const handleSelectJobCustomerVehicle = (event,data) => {
        event.preventDefault();
        setSelectedCustomerVehicle(data.value);
    }

    const updateJobCustomerVehicleButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobCustomerVehicleModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateJobCustomerVehicleModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobCustomerVehicleModal} onOpen={openUpdateJobCustomerVehicleModal} trigger={updateJobCustomerVehicleButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobCustomerVehicle} error={error}>
                            <FormDropdown required label={t(Text.UI_JOB_DETAILS_CARPLATENUMBER)} placeholder={t(Text.UI_DROPDOWN_CUSTOMERVEHICLE_PLACEHOLDER)} fluid selection search options={customerVehicleOptions} onSearchChange={handleOnSearchChange} onChange={handleSelectJobCustomerVehicle}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error visible={error} header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}