import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalContent, ModalDescription, Segment, Container } from 'semantic-ui-react';
import { FilePond,registerPlugin} from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType,FilePondPluginFileRename)

export default function UploadAttachmentModal (props) {
    const authentication = useSelector(state => state.authentication)
    const job = useSelector(state => state.job);
    const maxFiles = 10;

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState(null)

    const uploadAttachmentButton = () => {
        return(
            <Button className='button-margin' fluid={false} circular size="large" icon='add'/>
        )
    }

    const closeUploadAttachmentModal = () => {
        setFiles(null)
        setOpen(false);
    }

    const openUploadAttachmentModal = () => {
        setFiles(null)
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUploadAttachmentModal} onOpen={openUploadAttachmentModal} trigger={uploadAttachmentButton()}>
            <Segment basic>
                <ModalContent>
                    <ModalDescription>
                        <h4>{t(Text.UI_JOB_ATTACHMENT_UPLOADIMAGE)}</h4>
                        <h5>{t(Text.UI_JOB_ATTACHMENT_ENSURE)}</h5>
                        <label>{t(Text.UI_JOB_ATTACHMENT_ATTACHMENTRULES1)}{maxFiles}</label><br/>
                        <label>{t(Text.UI_JOB_ATTACHMENT_ATTACHMENTRULES2)}</label><br/>
                        <br/>
                        <Container>
                                <FilePond
                                    server={{
                                        url: ENDPOINT.JOB_ENDPOINT+"/"+job.data.id+"/addAttachment",
                                        process: {
                                            method: 'POST',
                                            withCredentials: true,
                                            headers: { 
                                                'Authorization': authentication.authorization
                                            },
                                            timeout: 70000,
                                            onload: () => {
                                            }
                                        }
                                    }}
                                    fileRenameFunction={(file) =>
                                        new Promise((resolve) => {
                                            var input = window.prompt('Enter new filename', file.name.replace(file.extension,""))
                                            if(input === null)
                                            {
                                                input = file.name
                                            }
                                            else
                                            {
                                                input = input+file.extension
                                            }
                                            resolve(input);
                                        })}
                                    files={files}
                                    name='file'
                                    maxParallelUploads={maxFiles}
                                    maxFiles={maxFiles}
                                    allowRevert={false}
                                    allowMultiple={true}
                                    allowFileTypeValidation={true}
                                    onprocessfiles={async () => {props.onRefresh()}} 
                                    onupdatefiles={setFiles}
                                    onerror={async () => {props.onRefresh()}}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                        </Container>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}