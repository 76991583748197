import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, FormDropdown, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateIncidentCategoryModal (props) {

    const authentication = useSelector(state => state.authentication)

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [category, setCategory] = useState(props?.incident?.category);

    const CategoryOptions = [
        {
            key: 'CUSTOMER',
            text: t(Text.UI_INCIDENT_DETAILS_CUSTOMER),
            value: 'CUSTOMER'
        },
        {
            key: 'SUPPLIER',
            text: t(Text.UI_INCIDENT_DETAILS_SUPPLIER),
            value: 'SUPPLIER'
        },
        {
            key: 'VEBO',
            text: t(Text.UI_INCIDENT_DETAILS_VEBO),
            value: 'VEBO'
        }
    ]

    const handleUpdateIncidentCategoryModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateCategory = Object.fromEntries(new FormData(event.target).entries());
        updateCategory.category = category;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateCategory)
        }

        const response = await fetch(ENDPOINT.INCIDENT_ENDPOINT+"/"+props?.incident?.id+"/updateCategory",options)
        const body = await response.json();

        if(response.ok)
        {
            props?.setIncident?.(body);
            setCategory(body.category);
            closeUpdateIncidentCategoryModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
        }
        setLoading(false)
    }

    const updateIncidentCategoryButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateIncidentCategoryModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateIncidentCategoryModal = () => {
        setOpen(true);
    }

    const handleSelectCategory = (event,data) => {
        event.preventDefault();
        setCategory({key:data.value});
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateIncidentCategoryModal} onOpen={openUpdateIncidentCategoryModal} trigger={updateIncidentCategoryButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateIncidentCategoryModal} error={error}>
                            <FormDropdown label={t(Text.GENERIC_DATA_INCIDENT_CATEGORY)} name="category" fluid selection required options={CategoryOptions} defaultValue={category.key} onChange={handleSelectCategory}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}