import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateJobTransactionNumberModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleUpdateJobTransactionNumberModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateJobTransactionNumber = Object.fromEntries(new FormData(event.target).entries());

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateJobTransactionNumber)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateTransactionNumber",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobTransactionNumberModal();
            dispatch(getJob({jobId:body.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobTransactionNumberModal = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobTransactionNumberModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateJobTransactionNumberModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobTransactionNumberModal} onOpen={openUpdateJobTransactionNumberModal} trigger={updateJobTransactionNumberModal()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobTransactionNumberModal} error={error}>
                            <FormInput required label={t(Text.UI_JOB_DETAILS_TRANSACTIONINVOICENUMBER)} type="text" name="transactionNumber" placeholder={t(Text.UI_JOB_DETAILS_TRANSACTIONINVOICENUMBER)} defaultValue={props.job?.serviceData?.transactionNumber}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}