import "./JobCard.css"
import "./Dashboard.css";
import Roles from "../../constants/Roles";
import Text from '../../constants/Text';
import PATH from '../../constants/Path';
import React, { useCallback, useEffect, useState } from 'react'
import moment from "moment";
import { JOB_ENDPOINT } from "../../constants/API"
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionContent, AccordionTitle, Card, CardContent, Icon, Menu, MenuItem, Transition, Loader, Dimmer } from 'semantic-ui-react'
import { RoleAllowed, VisibleCondition } from '..';
import { useSelector } from "react-redux";
import { TimerFlip } from "../common/TimerFlip";

export default function JobCard (props) {

    const {t} = useTranslation();
    const authentication = useSelector(state => state.authentication);
    const [cardExpand, setCardExpand] = useState(false);
    const [job, setJob] = useState(null);

    const [jobStatusChangeHistory, setJobStatusChangeHistory] = useState([]);
    const [loadingJobStatusChangeHistory, setLoadingJobStatusChangeHistory] = useState(false);
    const [checkIn, setCheckIn] = useState(null);
    const [startJob, setStartJob] = useState(null);
    
    const jobHeader = props.jobHeader;
    const firstName = jobHeader?.serviceHeaderData?.customerHeaderData?.firstName;
    const lastName = jobHeader?.serviceHeaderData?.customerHeaderData?.lastName;
    const model = jobHeader?.serviceHeaderData?.customerVehicleHeaderData?.vehicleHeaderData?.model;
    const brand = jobHeader?.serviceHeaderData?.customerVehicleHeaderData?.vehicleHeaderData?.brand;
    const card_header = firstName?.concat(" ",model?model:brand);
    const registrationNumber = jobHeader?.serviceHeaderData?.customerVehicleHeaderData?.registrationNumber;
    
    const getJobDetails = async () => {
        const response = await fetch(JOB_ENDPOINT+'/'+jobHeader.id,{
            method: 'GET',
                headers: new Headers({
                'Authorization': authentication.authorization
            }),
            redirect: 'follow'
        });

        if(response.ok){
            setJob(await response.json());
        }
        else{
            setJob(null);
        }
    }

    const getJobStatusChangeHistory = useCallback(async () =>{
        setLoadingJobStatusChangeHistory(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(JOB_ENDPOINT+"/"+jobHeader?.id+"/statusChangeHistory",options)
        const body = await response.json();
        if(response.ok)
        {
            setJobStatusChangeHistory(body)
        }
        setLoadingJobStatusChangeHistory(false);
    },[authentication,jobHeader?.id]);

    useEffect(() => {
        getJobStatusChangeHistory();
    },[getJobStatusChangeHistory])

    const toggleCardExpand = () => {
        if(cardExpand){
            setCardExpand(false);
        }
        else{
            getJobDetails();
            setCardExpand(true);
        }
    }

    useEffect(() => {
        if(jobStatusChangeHistory?.length > 0 && !loadingJobStatusChangeHistory){
            let checkedInStatuses = jobStatusChangeHistory.filter(n => n.toStatus?.key === "CHECKED_IN");
            let startedStatuses = jobStatusChangeHistory.filter(n => n.toStatus?.key === "STARTED");

            if(checkedInStatuses.length > 0){
                setCheckIn(checkedInStatuses[checkedInStatuses.length - 1])
            }

            if(startedStatuses.length > 0){
                setStartJob(startedStatuses[startedStatuses.length - 1])
            }
        }
    },[setCheckIn,setStartJob,jobStatusChangeHistory,loadingJobStatusChangeHistory])

    return(
        <Card className="link-text-black" color='red' fluid>
            <Menu attached className="menu-header" secondary href={generatePath(PATH.JOB_DETAILS_PAGE,{id:jobHeader.id})}>
                <MenuItem header className="menu-item-header" position="left" as={"h3"} content={card_header.toUpperCase()} />
                <MenuItem header className="menu-item-header" position="right" content={registrationNumber.toUpperCase()} />
            </Menu>
            <Menu attached compact borderless>
                <MenuItem header position="left" content={"SA : ".concat(" ",jobHeader?.serviceAdvisor?.firstName)} />
                <MenuItem header position="right" content={"LT : ".concat(" ",jobHeader?.serviceLeadTechnician?.firstName)} />
            </Menu>
            <VisibleCondition condition={checkIn && (jobHeader?.jobStatus?.key === "CHECKED_IN" || jobHeader?.jobStatus?.key === "STARTED")}>
                <Menu attached compact borderless>
                    <MenuItem header position="left">
                        <span className="major">{t(Text.GENERIC_DATA_CHECKIN)} : </span>
                        <TimerFlip format={["Day", "Hour", "Minute"]} labels = {["Days", "Hours", "Minutes"]} tickView={"text"} value={moment(checkIn?.timestamp)?.toDate()?.toISOString()} />
                    </MenuItem>
                </Menu>
            </VisibleCondition>
            <VisibleCondition condition={startJob && jobHeader?.jobStatus?.key === "STARTED"}>
                <Menu attached compact borderless>
                    <MenuItem header position="left">
                        <span className="major">{t(Text.GENERIC_DATA_STARTJOB)} : </span>
                        <TimerFlip format={["Day", "Hour", "Minute"]} labels = {["Days", "Hours", "Minutes"]} tickView={"text"} value={moment(startJob?.timestamp)?.toDate()?.toISOString()} />
                    </MenuItem>
                </Menu>
            </VisibleCondition>
            <Accordion className='no-margin'>
                <Transition visible={cardExpand} animation='fade down' duration={300}>
                    <AccordionContent active={cardExpand}>
                        <CardContent textAlign="left">
                            {job &&
                                <div>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_LASTCHANGEDON)}:</h4>
                                    <p className='no-margin'>{job.lastModifiedDate.replaceAll("-","/")}</p>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_LASTCHANGEDBY)}:</h4>
                                    <p className='no-margin'>{job.lastModifiedByName}</p>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMERNAME)}:</h4>
                                    <p className='no-margin'>{firstName +" "+ lastName}</p>
                                    <br/>
                                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER,Roles.ROLE_ADVISOR]}>
                                        <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_PHONENUMBER)}:</h4>
                                        <p className='no-margin'>{job.serviceData.customerData.mobileNumber}</p>
                                        <br/>
                                    </RoleAllowed>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_VEHICLE_TYPE)}:</h4>
                                    <p className='no-margin'>{(job.serviceData.customerVehicleData.vehicleData.posId).toUpperCase()}</p>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_SERVICE_MILEAGE)}:</h4>
                                    <p className='no-margin'>{job.serviceData.mileage}</p>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}:</h4>
                                    <VisibleCondition condition={job.customerRequest}>
                                        <p className="support-line-break">{job.customerRequest}</p>
                                    </VisibleCondition>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}:</h4>
                                    <VisibleCondition condition={job.description}>
                                        <p className="support-line-break">{job.description}</p>
                                    </VisibleCondition>
                                    <br/>
                                    <h4 className='no-margin'>{t(Text.UI_JOB_DETAILS_ABNORMALITIES)}:</h4>
                                    <VisibleCondition condition={job.abnormalities}>
                                        <p className="support-line-break">{job.abnormalities}</p>
                                    </VisibleCondition>
                                </div>
                            }
                            {!job &&
                                <div>
                                    <Dimmer active inverted>
                                        <Loader>Loading</Loader>
                                    </Dimmer>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            }
                        </CardContent>
                    </AccordionContent>
                </Transition>
                <AccordionTitle onClick={() => toggleCardExpand()}>
                    <Icon name={cardExpand?"minus":"plus"}/>
                </AccordionTitle>
            </Accordion>
        </Card>
    )
}