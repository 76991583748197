// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup{
    padding:0em!important;
    border:0!important;
}

.top-menu{
    background-color:#333333!important;
}

.top-menu-header{
    margin:0.5em!important;
}

.sidebar{
    width: 18.5em!important;
}

.sidebar-pusher{
    margin-top:3em!important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Navigation.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".popup{\n    padding:0em!important;\n    border:0!important;\n}\n\n.top-menu{\n    background-color:#333333!important;\n}\n\n.top-menu-header{\n    margin:0.5em!important;\n}\n\n.sidebar{\n    width: 18.5em!important;\n}\n\n.sidebar-pusher{\n    margin-top:3em!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
