import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormDropdown } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

export default function UpdateJobOldPartsModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const [selectedOldPartStatus, setSelectedOldPartStatus] = useState(null);

    const oldPartStatusOptions = [
        {
            key: 'KEEP_FOR_CUSTOMER',
            text: t(Text.UI_JOB_MODAL_KEEP),
            value: 'KEEP_FOR_CUSTOMER'
        },
        {
            key: 'DISCARD',
            text: t(Text.UI_JOB_MODAL_DISCARD),
            value: 'DISCARD'
        }

    ]
    
    const handleSelectOldPartStatus = (event,data) => {
        event.preventDefault();
        setSelectedOldPartStatus({key:data.value});
    }

    const handleUpdateJobOldPartsModal = async(event) => {
        setLoading(true);
        event.preventDefault();
        const updateOldParts = Object.fromEntries(new FormData(event.target).entries());

        updateOldParts.oldPartStatus = selectedOldPartStatus;

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateOldParts)
        }
        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/"+props.job?.id+"/updateOldPartStatus",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobOldPartsModal();
            dispatch(getJob({jobId:body.id}))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobOldPartsModal = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobOldPartsModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateJobOldPartsModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobOldPartsModal} onOpen={openUpdateJobOldPartsModal} trigger={updateJobOldPartsModal()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobOldPartsModal} error={error}>
                            <FormDropdown required label={t(Text.UI_JOB_DETAILS_OLDPARTSTATUS)} name="oldPartStatus" placeholder="What to do with Old Parts?" fluid selection options={oldPartStatusOptions} onChange={handleSelectOldPartStatus}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}